// src/i18n.js
import { addMessages, init, getLocaleFromNavigator } from "svelte-i18n";

import enGB from "./assets/en-GB.json";
import nlNL from "./assets/nl-NL.json";
import svSE from "./assets/sv-SE.json";

addMessages("en-GB", enGB);
addMessages("nl-NL", nlNL);
addMessages("sv-SE", svSE);

init({
  fallbackLocale: "nl-NL",
  initialLocale: getLocaleFromNavigator(),
});
