<script lang="ts">
  import Select from "svelte-select";
  import Flag from "./Flag.svelte";
  import { locale } from "svelte-i18n";
  import { _ } from "svelte-i18n";
  import { WEB_URL } from "../utils/constants";

  // State
  let items = [
    { value: "en-GB", label: "English" },
    { value: "sv-SE", label: "Svenska" },
    { value: "nl-NL", label: "Nederlands" },
  ];
  let value = items.find((item) => item.value === $locale) ?? items[0];
</script>

<div class="celebratix-widget-container">
  <div class="celebratix-widget-left-container">
    <div class="celebratix-widget-text-s">
      {$_("footer.powered_by")}
    </div>
    <a target="_blank" href={WEB_URL}>
      <img src={`${import.meta.env.VITE_CDN_URL}/icons/logo.svg`} alt="CLBTX" />
    </a>
  </div>
  <Select
    bind:value
    bind:justValue={$locale}
    {items}
    showChevron={true}
    clearable={false}
    searchable={false}
    inputStyles="cursor: pointer;"
    containerStyles="box-sizing: border-box; cursor: pointer;"
    --background="transparent"
    --list-background="#32302E"
    --border="none"
    --border-focused="none"
    --border-hover="none"
    --height="100%"
    --width="175px"
    --padding="0px 8px 0px 8px"
    --value-container-padding="0px"
    --chevron-width="fit-content"
    --selected-item-padding="0px 8px 0px 0px"
    --item-hover-bg="#494745"
    --item-is-active-bg="transparent"
    --list-border-radius="4px"
  >
    <div slot="selection" let:selection class="celebratix-widget-selection">
      <Flag country={selection.value} />
      <div class="celebratix-widget-text-xs-bold">
        {selection.label}
      </div>
    </div>
    <div slot="item" let:item class="celebratix-widget-item">
      <div class="celebratix-widget-item-inner">
        <Flag country={item.value} />
        {#if item.value === value?.value}
          <div class="celebratix-widget-text-xs-bold">
            {item.label}
          </div>
        {:else}
          <div class="celebratix-widget-text-xs">
            {item.label}
          </div>
        {/if}
      </div>
      {#if item.value === value?.value}
        <img
          src={`${import.meta.env.VITE_CDN_URL}/icons/radio-on.svg`}
          alt="On"
        />
      {:else}
        <img
          src={`${import.meta.env.VITE_CDN_URL}/icons/radio-off.svg`}
          alt="Off"
        />
      {/if}
    </div>
  </Select>
</div>

<style>
  .celebratix-widget-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 640px;
  }
  .celebratix-widget-left-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
  }
  .celebratix-widget-selection {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .celebratix-widget-item {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 52px;
    cursor: pointer;
    justify-content: space-between;
  }
  .celebratix-widget-item-inner {
    display: flex;
    align-items: center;
    gap: 8px;
  }
</style>
