<script lang="ts">
  export let id: string;
  export let checked: boolean;
  export let label: string | undefined = undefined;
  export let info = "";
  export let error = "";
</script>

<div class="celebratix-widget-container">
  <div class="celebratix-widget-check-container celebratix-widget-text-base">
    <input type="checkbox" {id} bind:checked />
    {#if label}
      <label for={id}>{label}</label>
    {/if}
  </div>
  {#if error}
    <div class="celebratix-widget-text-s celebratix-widget-error">{error}</div>
  {:else if info}
    <div class="celebratix-widget-text-s celebratix-widget-info">{info}</div>
  {/if}
</div>

<style>
  .celebratix-widget-check-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .celebratix-widget-container {
    display: flex;
    flex-direction: column;
  }
  .celebratix-widget-info {
    padding-top: 4px;
    color: #a8a29e;
  }
  .celebratix-widget-error {
    padding-top: 4px;
    color: #ff5e7c;
  }
</style>
