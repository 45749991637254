<script lang="ts">
  import { onMount } from "svelte";
  import Card from "../components/Card.svelte";
  import { _ } from "svelte-i18n";
  import {
    magic,
    orderId,
    pendingPayment,
    selectedEventId,
  } from "../store/global";
  import { page } from "../store/navigation";
  import { useTrackEvent } from "../api/mutationHooks";

  const { trackFacebookEvent } = useTrackEvent();

  // Clear pending payment on mount to cancel potential order on redirect
  onMount(() => {
    pendingPayment.set(false);

    trackFacebookEvent({
      trackingEventName: "ViewContent",
      contentCategory: $page.at(-1)?.page ?? null,
      contentIds: $selectedEventId ? [$selectedEventId.toString()] : null,
      contentType: $selectedEventId ? "event" : null,
    });
  });

  const ticketUrl = () => {
    const relative = $magic
      ? `/order/${$magic}`
      : `/event/${$selectedEventId}/order/${$orderId}`;
    return new URL(relative, import.meta.env.VITE_APP_URL).toString();
  };
</script>

<Card>
  <div class="celebratix-widget-container">
    <div class="celebratix-widget-inner-container">
      <div class="celebratix-widget-h1">
        {$_("payment_success_screen.title")}
      </div>
      <div class="celebratix-widget-text-base-bold">
        {$_("payment_success_screen.description")}
      </div>
      <button on:click={() => window.open(ticketUrl())}>
        <div class="celebratix-widget-text-base-bold">
          {$_("payment_success_screen.link")}
        </div>
      </button>
      <div class="celebratix-widget-text-s-bold celebratix-widget-italic">
        {$_("payment_success_screen.footer")}
      </div>
    </div>
    <img src={`${import.meta.env.VITE_CDN_URL}/icons/clbtx.svg`} alt="CLBTX" />
  </div>
</Card>

<style>
  .celebratix-widget-italic {
    font-style: italic;
  }
  .celebratix-widget-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 40px;
    padding: 40px 32px 40px 32px;
  }
  .celebratix-widget-inner-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
</style>
