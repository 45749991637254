<script lang="ts">
  import { onDestroy } from "svelte";
  import { eventFacebookPixelId } from "../store/global";
  import { params } from "../store/params";

  // prettier-ignore
  // @eslint-ignore
  // @ts-ignore
  // eslint-disable-next-line
  !function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');

  // Stop sending PageView events on history state changes
  // @ts-expect-error
  // eslint-disable-next-line no-undef
  fbq.disablePushState = true;

  const unsubscribeParams = params.subscribe((value) => {
    if (value.pixel) {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      fbq("init", value.pixel);
    }
  });

  const unsubscribeEventFBPixelId = eventFacebookPixelId.subscribe((value) => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    if (!fbq) return;
    // @ts-ignore
    // eslint-disable-next-line no-undef
    if (!fbq?.getState) return;

    // @ts-ignore
    // eslint-disable-next-line no-undef
    if (value && !fbq?.getState()?.pixels?.some((p) => p.id === value)) {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      fbq("init", value);
    }
  });

  onDestroy(() => {
    unsubscribeParams();
    unsubscribeEventFBPixelId();
  });
</script>

<noscript>
  <!-- svelte-ignore a11y-missing-attribute -->
  <img
    height="1"
    width="1"
    style="display:none"
    src="https://www.facebook.com/tr?id={$eventFacebookPixelId}&ev=PageView&noscript=1"
  />
  <!-- svelte-ignore a11y-missing-attribute -->
  <img
    height="1"
    width="1"
    style="display:none"
    src="https://www.facebook.com/tr?id={$params.pixel}&ev=PageView&noscript=1"
  />
</noscript>
