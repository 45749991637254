<script lang="ts">
  import { slide } from "svelte/transition";
  import { Icon, Plus, Minus } from "svelte-hero-icons";
  import { selectedListing } from "../store/global";
  import { addPage } from "../store/navigation";
  import { _ } from "svelte-i18n";
  import type { MarketplaceListingBasicDto } from "../api/generated/v1";
  import type { ConsumersTicketTypeDto } from "../api/generated/v2";
  import { displayPrice } from "../utils/utils";

  // Params
  export let ticket: ConsumersTicketTypeDto;
  export let listings: MarketplaceListingBasicDto[];
  export let tosAccepted: boolean;

  // State
  let open = false;

  const cheapestListing = listings.reduce((prev, curr) =>
    prev.listingPrice < curr.listingPrice ? prev : curr,
  );

  const onClickListing = (listing: MarketplaceListingBasicDto) => {
    selectedListing.set(listing);
    // Navigate to register screen
    addPage({ page: "RegisterScreen", title: "information", index: 3 });
  };
</script>

<div>
  <button class="celebratix-widget-header" on:click={() => (open = !open)}>
    <div class="celebratix-widget-header-title">
      <div class="celebratix-widget-text-base-bold">
        {ticket.name}
      </div>
      <div class="celebratix-widget-header-inner">
        <div class="celebratix-widget-test-base">
          {$_("tickets_screen.listing_1")}
        </div>
        <div class="celebratix-widget-text-base-bold">
          {displayPrice(cheapestListing.listingPrice, cheapestListing.currency)}
        </div>
        <div class="celebratix-widget-text-base-bold">
          {listings.length}
          {$_("tickets_screen.listing_2")}
        </div>
      </div>
    </div>
    <div class="celebratix-widget-icon">
      {#if open}
        <Icon src={Minus} mini />
      {:else}
        <Icon src={Plus} mini />
      {/if}
    </div>
  </button>
  {#if open}
    <div transition:slide class="celebratix-widget-listings">
      {#each listings as listing}
        <div class="celebratix-widget-listing">
          <div class="celebratix-widget-listing-left">
            <div class="celebratix-widget-text-l">
              {displayPrice(listing.listingPrice, listing.currency)}
            </div>
            <div class="celebratix-widget-text-s)">
              {listing.seller?.fullName}
            </div>
          </div>
          <button
            class="celebratix-widget-buy-button"
            disabled={!tosAccepted}
            on:click={() => onClickListing(listing)}
          >
            <div class="celebratix-widget-text-base-bold">
              {$_("tickets_screen.order")}
            </div>
          </button>
        </div>
      {/each}
    </div>
  {/if}
</div>

<style>
  button:disabled {
    background-color: #d0cfce;
    color: #a8a29e;
    cursor: auto;
  }
  .celebratix-widget-buy-button {
    width: 48px;
    height: 48px;
    padding: 0px 20px 0px 20px;
  }
  .celebratix-widget-listings {
    display: flex;
    flex-direction: column;
  }
  .celebratix-widget-listing {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 32px;
    background-color: #f5f5f4;
  }
  .celebratix-widget-icon {
    color: #1c1917;
    height: 24px;
    width: 24px;
  }
  .celebratix-widget-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 20px 32px;
    background-color: #fafaf9;
    box-sizing: border-box;
    height: unset;
  }
  .celebratix-widget-header-title {
    display: flex;
    flex-direction: column;
  }
  .celebratix-widget-header-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .celebratix-widget-listing-left {
    display: flex;
    flex-direction: column;
  }
</style>
