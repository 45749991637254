<script lang="ts">
  import { createQuery } from "@tanstack/svelte-query";
  import Card from "../components/Card.svelte";
  import EventListItem from "../components/EventListItem.svelte";
  import { onMount } from "svelte";
  import { channelSlug, selectedEventId } from "../store/global";
  import { page } from "../store/navigation";
  import { useTrackEvent } from "../api/mutationHooks";
  import { queryEvents } from "../api/queryOptions";
  import { params } from "../store/params";
  import { _ } from "svelte-i18n";

  const eventsState = createQuery(
    queryEvents({
      channel: $channelSlug ?? $params.channel,
    }),
  );

  const { trackFacebookEvent } = useTrackEvent();

  // Track view content event
  onMount(() => {
    trackFacebookEvent({
      trackingEventName: "ViewContent",
      contentCategory: $page.at(-1)?.page ?? null,
      contentIds: $selectedEventId ? [$selectedEventId.toString()] : null,
      contentType: $selectedEventId ? "event" : null,
    });
  });
</script>

<Card loading={$eventsState.isLoading}>
  <!-- No events found error -->
  {#if !$eventsState.isLoading && $eventsState.data?.data?.list.length === 0}
    <div class="celebratix-widget-error-container">
      <div class="celebratix-widget-h3">{$_("events_screen.title")}</div>
      <div class="celebratix-widget-error-content">
        <div class="celebratix-widget-text-base">
          {$_("events_screen.description")}
        </div>
        <button
          on:click={() => {
            const href = window.location.href;
            const url = new URL(href);
            window.location.replace(url.origin + url.pathname);
          }}
        >
          <div class="celebratix-widget-text-base-bold">
            {$_("events_screen.button")}
          </div>
        </button>
      </div>
    </div>
  {:else}
    <div class="celebratix-widget-container" data-testid="event-list">
      {#each $eventsState.data?.data?.list ?? [] as event, i}
        <div>
          <EventListItem {event} />
          {#if i !== ($eventsState.data?.data?.list ?? []).length - 1}
            <div class="celebratix-widget-separator" />
          {/if}
        </div>
      {/each}
    </div>
  {/if}
</Card>

<style>
  .celebratix-widget-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  .celebratix-widget-error-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px 32px 40px 32px;
  }
  .celebratix-widget-error-content {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 20px;
    width: 100%;
  }
  .celebratix-widget-separator {
    height: 1px;
    width: 100%;
    background-color: #191919;
  }
</style>
