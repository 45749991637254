import { AppClientV1 } from "./generated/v1";
import { AppClientV2 } from "./generated/v2";

export const basePath = import.meta.env.VITE_API_URL;

const appClientV1 = new AppClientV1({
  BASE: basePath,
});

const appClientV2 = new AppClientV2({
  BASE: basePath,
});

const getAccessToken = (request: RequestInit) => {
  const jwt = localStorage.getItem("celebratix-widget-jwt");
  // Add token to request headers
  if (jwt) {
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${jwt}`,
    };
  }
};

const appId = `widget:${import.meta.env.PACKAGE_VERSION}`;

async function reqInterceptor(request: RequestInit) {
  getAccessToken(request);

  request.headers = {
    ...request.headers,
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-Celebratix-App-Id": appId,
  };
  return request;
}

appClientV1.request.config.interceptors.request.use(reqInterceptor);
appClientV2.request.config.interceptors.request.use(reqInterceptor);

export const {
  orders: ordersApiV1,
  account: accountApiV1,
  countries: countriesApiV1,
  marketplace: marketplaceApiV1,
} = appClientV1;

export const {
  events: eventsApiV2,
  analytics: analyticsApiV2,
  trackingLinks: trackingLinksApiV2,
  orders: ordersApiV2,
  resale: resaleApiV2,
} = appClientV2;
