<script lang="ts">
  import Card from "../components/Card.svelte";
  import EventDetails from "../components/EventDetails.svelte";
  import TicketSelector from "../components/TicketSelector.svelte";
  import Total from "../components/Total.svelte";
  import {
    channelSlug,
    couponCode,
    eventFacebookPixelId,
    selectedEventId,
    selectedTickets,
  } from "../store/global";
  import { addPage, constructParams, page } from "../store/navigation";
  import CheckboxInput from "../components/CheckboxInput.svelte";
  import { _ } from "svelte-i18n";
  import Marketplace from "../components/Marketplace.svelte";
  import { createQuery } from "@tanstack/svelte-query";
  import { params } from "../store/params";
  import { useTrackEvent } from "../api/mutationHooks";
  import { queryCouponTickets, queryEvent } from "../api/queryOptions";
  import { onDestroy, onMount } from "svelte";
  import TextInput from "../components/TextInput.svelte";
  import { isFutureDate } from "../utils/utils";
  import { derived } from "svelte/store";
  import { selectedListing } from "../store/global";
  import { TERMS_AND_CONDITIONS_URL } from "../utils/constants";

  interface Inputs {
    couponCode: { value: string; info: string; error: string; success: string };
  }

  // State
  let tosAccepted = false;
  let tryMarketplace = false;
  let inputs: Inputs = {
    couponCode: { value: $couponCode ?? "", info: "", error: "", success: "" },
  };
  let showCouponCode = false;

  const { trackFacebookEvent } = useTrackEvent();

  const eventState = createQuery(
    queryEvent($selectedEventId, $channelSlug ?? $params.channel ?? undefined),
  );

  const couponState = createQuery(
    derived([selectedEventId, couponCode], ([$selectedEventId, $couponCode]) =>
      queryCouponTickets($selectedEventId, $couponCode ?? undefined),
    ),
  );

  couponState.subscribe(({ data }) => {
    if (data?.isSuccess) {
      showCouponCode = true;

      const tickets = data?.data?.tickets;
      // Only nullable if the request is not successful
      if (!tickets) return;

      if (tickets.length === 0) {
        inputs["couponCode"].error = `${$_(
          "tickets_screen.coupon_code_error_2",
        )} ${inputs["couponCode"].value} ${$_(
          "tickets_screen.coupon_code_error_3",
        )}`;
      } else {
        // Update success message and clear error
        inputs["couponCode"].error = "";
        inputs["couponCode"].success = `${$_(
          "tickets_screen.coupon_code_success_1",
        )} ${inputs["couponCode"].value} ${$_(
          "tickets_screen.coupon_code_success_2",
        )}`;
      }
    }
  });

  // Used to translate errors if users changes language
  const translateErrors = () => {
    if (inputs["couponCode"].error) {
      inputs["couponCode"].error = `${$_(
        "tickets_screen.coupon_code_error_2",
      )} ${inputs["couponCode"].value} ${$_(
        "tickets_screen.coupon_code_error_3",
      )}`;
    }
    if (inputs["couponCode"].success) {
      inputs["couponCode"].success = `${$_(
        "tickets_screen.coupon_code_success_1",
      )} ${inputs["couponCode"].value} ${$_(
        "tickets_screen.coupon_code_success_2",
      )}`;
    }
  };

  const unsubscribeTranslation = _.subscribe(() => {
    translateErrors();
  });

  const unsubscribe = eventState.subscribe(({ data }) => {
    if (data?.data?.facebookPixelId) {
      eventFacebookPixelId.set(data.data.facebookPixelId);
    }
    if (data?.data) {
      trackFacebookEvent({
        trackingEventName: "ViewContent",
        contentCategory: $page.at(-1)?.page ?? null,
        contentIds: [data.data.id.toString()],
        contentName: data.data.name,
        contentType: "event",
      });
    }
  });

  const validateInputs = () => {
    let isValid = true;
    // Coupon code
    if (!inputs["couponCode"].value) {
      inputs["couponCode"].error = $_("tickets_screen.coupon_code_error_1");
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = () => {
    if (!validateInputs()) return;
    if (!$selectedEventId) return;

    // Add coupon code to URL
    const params = constructParams(`couponCode=${inputs["couponCode"].value}`);
    history.pushState({}, "", params);

    // Update current coupon code
    couponCode.set(inputs["couponCode"].value);

    // Remove all selected tickets from current event
    selectedTickets.update((value) => {
      if (!value[$selectedEventId]) return value;

      return {
        ...value,
        [$selectedEventId]: {
          ...value[$selectedEventId],
          quantity: 0,
          total: 0,
        },
      };
    });
  };

  const onClick = () => {
    selectedListing.set(undefined);
    // Navigate to register screen
    addPage({
      page: "RegisterScreen",
      title: "information",
      index: 3,
    });
  };

  onDestroy(unsubscribe);
  onDestroy(unsubscribeTranslation);

  onMount(() => {
    window.scrollTo(0, 0);
  });
</script>

<Card loading={$eventState.isLoading}>
  <div class="celebratix-widget-container">
    <div class="celebratix-widget-event-container">
      <EventDetails event={$eventState.data?.data} />
    </div>
    {#if $eventState.data?.data?.externalEventUrl}
      <div class="celebratix-widget-external-event">
        <button
          class="celebratix-widget-external-event-btn"
          on:click={() =>
            window.open(
              $eventState.data?.data?.externalEventUrl ?? undefined,
              "_self",
            )}
          ><div class="celebratix-widget-text-base-bold">
            {$_("tickets_screen.external_event")}
          </div>
        </button>
      </div>
    {:else}
      <div class="celebratix-widget-ticket-container">
        {#if tryMarketplace}
          <Marketplace bind:tryMarketplace {tosAccepted} />
        {:else}
          {#each $eventState.data?.data?.tickets ?? [] as ticket}
            <TicketSelector
              currency={$eventState.data?.data?.currency}
              {ticket}
              bind:tryMarketplace
            />
          {/each}
          <!-- Coupon code tickets -->
          {#each $couponState.data?.data?.tickets ?? [] as ticket}
            <TicketSelector
              currency={$eventState.data?.data?.currency}
              {ticket}
              bind:tryMarketplace
            />
          {/each}
          <!-- No tickets for sale -->
          {#if ($eventState.data?.data?.tickets ?? []).length === 0 && ($couponState.data?.data?.tickets ?? []).length === 0}
            <div class="celebratix-widget-empty">
              <div class="celebratix-widget-text-base">
                {$_("tickets_screen.no_tickets")}
              </div>
            </div>
          {/if}
        {/if}
        <div class="celebratix-widget-tos">
          <CheckboxInput id="checkbox" bind:checked={tosAccepted} />
          <div class="celebratix-widget-text-base">
            {$_("tickets_screen.tos_1")}
            {#if $eventState.data?.data?.termsAndConditionsUrl || $params.tos}
              <a
                class="celebratix-widget-link"
                target="_blank"
                href={$eventState.data?.data?.termsAndConditionsUrl ||
                  $params.tos}
              >
                {$_("tickets_screen.tos_2")}
              </a>
              {$_("tickets_screen.tos_3")}
              {$eventState.data?.data?.businessName ?? ""}
              {$_("tickets_screen.tos_4")}
            {/if}
            <a
              class="celebratix-widget-link"
              target="_blank"
              href={TERMS_AND_CONDITIONS_URL}
            >
              {$_("tickets_screen.tos_5")}
            </a>
            {$_("tickets_screen.tos_6")}
          </div>
        </div>
        {#if isFutureDate($eventState.data?.data?.endDate)}
          <div class="celebratix-widget-coupon-code-container">
            {#if showCouponCode}
              <TextInput
                --color="#1c1917"
                --background-color="#fafaf9"
                id="coupon_code"
                placeholder={$_("tickets_screen.coupon_code_placeholder")}
                bind:value={inputs["couponCode"].value}
                info={inputs["couponCode"].info}
                error={inputs["couponCode"].error}
                success={inputs["couponCode"].success}
              />
              <button on:click={handleSubmit} disabled={$couponState.isLoading}>
                <div class="celebratix-widget-text-base-bold">
                  {$_("tickets_screen.coupon_code_button")}
                </div>
              </button>
            {:else}
              <button
                class="celebratix-widget-add-coupon-code-button"
                on:click={() => (showCouponCode = true)}
              >
                <div
                  class="celebratix-widget-text-base-bold celebratix-widget-add-coupon-code-text"
                >
                  {$_("tickets_screen.add_coupon_code")}
                </div>
              </button>
            {/if}
          </div>
        {/if}
      </div>
      <div
        class="celebratix-widget-total-container"
        style:background-color={tryMarketplace ? "#fafaf9" : "#e7e5e4"}
      >
        {#if !tryMarketplace}
          <Total
            currency={$eventState.data?.data?.currency}
            disabled={!tosAccepted}
            {onClick}
          />
        {/if}
      </div>
    {/if}
  </div>
</Card>

<style>
  .celebratix-widget-add-coupon-code-text {
    color: #78716c;
  }
  .celebratix-widget-add-coupon-code-button {
    all: unset;
    cursor: pointer;
  }
  .celebratix-widget-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  .celebratix-widget-event-container {
    padding: 40px 32px 40px 32px;
  }
  .celebratix-widget-ticket-container {
    background-color: #fafaf9;
    color: #1c1917;
  }
  .celebratix-widget-total-container {
    color: #1c1917;
    border-radius: 0 0 24px 24px;
    min-height: 24px;
  }
  .celebratix-widget-tos {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 20px 32px 20px 32px;
    box-sizing: border-box;
  }
  .celebratix-widget-external-event {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
  }
  .celebratix-widget-external-event-btn {
    max-width: 400px;
  }
  .celebratix-widget-coupon-code-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px 32px 20px 32px;
    gap: 8px;
  }
  button {
    width: fit-content;
    height: 52px;
    color: #1c1917;
    padding: 0px 20px 0px 20px;
  }
  button:disabled {
    background-color: #d0cfce;
    color: #a8a29e;
    cursor: auto;
  }
  .celebratix-widget-empty {
    padding: 20px 32px;
  }
</style>
