<script lang="ts">
  import Card from "../components/Card.svelte";
  import { loadStripe } from "@stripe/stripe-js";
  import { onDestroy, onMount } from "svelte";
  import {
    currentOrder,
    orderId,
    paymentDetails,
    pendingPayment,
    selectedEventId,
    selectedPaymentMethod,
    userEmail,
    userFullName,
  } from "../store/global";
  import type {
    Stripe,
    StripeElements,
    StripeElementsOptionsMode,
  } from "@stripe/stripe-js";
  import TotalPayment from "../components/TotalPayment.svelte";
  import { addPage, page } from "../store/navigation";
  import { _ } from "svelte-i18n";
  import { useTrackEvent } from "../api/mutationHooks";
  import { ordersApiV2 } from "../api/instances";
  import type { SubmitOrderDtoOperationResult } from "../api/generated/v2";

  // State
  let stripe: Stripe | null;
  let elements: StripeElements;
  let seconds: number | undefined;
  let minutes: number | undefined;
  let interval: NodeJS.Timeout | undefined;

  const { trackFacebookEvent } = useTrackEvent();

  // Track view content event
  onMount(() => {
    trackFacebookEvent({
      trackingEventName: "ViewContent",
      contentCategory: $page.at(-1)?.page ?? null,
      contentIds: $selectedEventId ? [$selectedEventId.toString()] : null,
      contentType: $selectedEventId ? "event" : null,
    });
  });

  const handleServerResponse = async (
    response: SubmitOrderDtoOperationResult,
  ) => {
    if (!stripe) return;
    if (!$currentOrder) return;

    if (response.data?.stripeStatus === "requires_action") {
      // Use Stripe.js to handle the required next action
      const { error } = await stripe.handleNextAction({
        clientSecret: response.data.paymentIntentClientSecret,
      });

      if (error) {
        // Show error from Stripe.js in payment form
        console.error(error);
      } else {
        // Actions handled, show success message
      }
    } else {
      // Actions not required, assume it was successful
      window.location.href = `${window.location.origin}${window.location.pathname}?orderId=${$currentOrder.orderId}&eventId=${$selectedEventId}&magic=${$currentOrder.magic}&redirect_status=succeeded`;
    }
  };

  const submit = async () => {
    if (!stripe) return;
    if (!$currentOrder) return;

    // Used to prevent cancel order on redirect
    pendingPayment.set(true);

    const { error: validationError } = await elements.submit();
    if (validationError) {
      pendingPayment.set(false);
      return;
    }

    const { error, confirmationToken } = await stripe.createConfirmationToken({
      elements,
      params: {
        payment_method_data: {
          billing_details: {
            name: $userFullName,
            email: $userEmail,
          },
        },
        return_url: `${window.location.origin}${window.location.pathname}?orderId=${$currentOrder.orderId}&eventId=${$selectedEventId}&magic=${$currentOrder.magic}`,
      },
    });

    if (error) {
      // TODO: Handle error
      // This point is only reached if there's an immediate error when
      // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
      console.error(error);
      pendingPayment.set(false);
      return;
    }

    try {
      const response =
        await ordersApiV2.postV2UsersOrdersByOrderIdSubmitConfirmationToken({
          orderId: $currentOrder.orderId,
          requestBody: {
            stripeConfirmationToken: confirmationToken.id,
          },
        });
      // Handle any next actions or errors
      await handleServerResponse(response);
    } catch (error) {
      console.error(error);
    }

    pendingPayment.set(false);
  };

  const unsubscribe = currentOrder.subscribe((value) => {
    if (!value) return;

    // Calculate the time left until the order expires
    interval = setInterval(() => {
      const now = new Date();
      const validUntil = new Date(value.validUntil);
      const diff = validUntil.getTime() - now.getTime();
      if (diff <= 0) {
        clearInterval(interval);
        currentOrder.set(undefined);
        orderId.set(undefined);
        addPage({
          page: "PaymentFailScreen",
          title: "payment",
          index: 1,
        });
        return;
      }
      minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.max(Math.floor((diff % (1000 * 60)) / 1000), 0);
    }, 1000);
  });

  onMount(() => {
    return () => {
      clearInterval(interval);
    };
  });

  onMount(async () => {
    stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

    if (!stripe) return;
    if (!$currentOrder) return;

    // Should be in the lowest unit possible, e.g. eurocents if currency is euro
    const amount = Math.round(
      $paymentDetails?.orderAmount &&
        $paymentDetails.orderCurrency?.decimalPlaces
        ? $paymentDetails.orderAmount *
            10 ** $paymentDetails.orderCurrency.decimalPlaces
        : 0,
    );

    const options: StripeElementsOptionsMode = {
      mode: "payment",
      paymentMethodCreation: "manual",
      paymentMethodConfiguration:
        $currentOrder.stripePaymentMethodConfigurationId || undefined,
      amount: amount,
      currency: ($paymentDetails?.orderCurrency?.code ?? "eur").toLowerCase(),
      customerOptions: {
        customer: $currentOrder.stripeCustomerId,
        // TODO: Keys can't be nullable
        ephemeralKey: $currentOrder.ephemeralKeySecret ?? "",
      },
      appearance: {
        theme: "stripe",
        labels: "above",
        variables: {
          colorPrimary: "#FAFAF9",
          colorBackground: "#000000",
          colorText: "#FAFAF9",
          borderRadius: "4px",
          colorDanger: "#FF5E7C",
          spacingUnit: "4px",
          fontFamily: "SupremeLLTT-Regular",
          fontSizeBase: "14px",
          fontWeightBold: "400",
          fontLineHeight: "1.2",
        },
        rules: {
          ".Input": {
            border: "solid 1px #78716c",
            color: "#FAFAF9",
            padding: "20px",
          },
          ".Input:focus": {
            border: "solid 1px #FAFAF9",
            boxShadow: "none",
          },
          ".Input:hover": {
            border: "solid 1px #FAFAF9",
          },
          ".Label": {
            color: "#FAFAF9",
            fontFamily: "SupremeLLTT-Light",
            fontSize: "14px",
            fontWeight: "300",
            lineHeight: "1.2",
          },
          ".Error": {
            fontFamily: "SupremeLLTT-Light",
            fontSize: "14px",
            fontWeight: "300",
            lineHeight: "1.2",
          },
        },
      },
      fonts: [
        {
          family: "SupremeLLTT-Light",
          src: 'url("__CSS_CDN_URL/fonts/SupremeLLTT-Light.ttf") format("truetype")',
        },
        {
          family: "SupremeLLTT-Regular",
          src: 'url("__CSS_CDN_URL/fonts/SupremeLLTT-Regular.ttf") format("truetype")',
        },
      ],
    };

    elements = stripe.elements(options);
    const paymentElement = elements.create("payment", {
      defaultValues: {
        billingDetails: {
          name: $userFullName,
          email: $userEmail,
        },
      },
    });
    paymentElement.mount("#payment-element");

    paymentElement.on("change", (event) => {
      if (event.elementType === "payment") {
        selectedPaymentMethod.set(event.value.type);
      }
    });
  });

  onDestroy(unsubscribe);
</script>

<Card>
  <div
    class="celebratix-widget-container"
    data-testid="payment-element-wrapper"
  >
    <form on:submit|preventDefault={submit}>
      <div id="payment-element" />
      <TotalPayment />
      <button disabled={$pendingPayment} data-testid="pay-button">
        <div class="celebratix-widget-text-base-bold">
          {$_("payment_screen.button")}
        </div>
      </button>
    </form>
    {#if $currentOrder && seconds !== undefined && minutes !== undefined}
      <div class="celebratix-widget-text-s">
        {$_("payment_screen.reserved_1")}
        {minutes > 0
          ? `${minutes} ${$_("payment_screen.reserved_2")} `
          : ""}{seconds}
        {$_("payment_screen.reserved_3")}
      </div>
    {/if}
  </div>
</Card>

<style>
  .celebratix-widget-container {
    display: flex;
    flex-direction: column;
    padding: 40px 32px 40px 32px;
    gap: 20px;
    color: #fafaf9;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
</style>
