import { writable } from "svelte/store";

export interface Page {
  page:
    | "EventsScreen"
    | "TicketsScreen"
    | "PaymentScreen"
    | "RegisterScreen"
    | "PaymentSuccessScreen"
    | "PaymentFailScreen"
    | "NotFoundScreen";
  title: "events" | "tickets" | "information" | "payment";
  index: number;
}

export const page = writable<Page[]>([]);

// Combines current search params with optional provided query params
export const constructParams = (queryParams?: string) => {
  const href = window.location.href;
  const searchParams = new URL(href).searchParams;

  // Prioritize new query eventId param
  // TODO: Extend this with generic approach if other query params are needed
  if (queryParams?.includes("eventId")) {
    searchParams.delete("eventId");
  }
  if (queryParams?.includes("couponCode")) {
    searchParams.delete("couponCode");
  }

  const params = searchParams.toString();

  let combinedParams = "";
  if (queryParams && params) {
    combinedParams = `?${queryParams}&${params}`;
  } else if (queryParams) {
    combinedParams = `?${queryParams}`;
  } else if (params) {
    combinedParams = `?${params}`;
  }

  return combinedParams;
};

// ACTIONS
export const addPage = (_page: Page, queryParams?: string) => {
  const params = constructParams(queryParams);

  page.update((value) => [...value, _page]);
  history.pushState(_page, "", params);
};

export const removePage = () => {
  page.update((value) => [...value.splice(0, value.length - 1)]);
};
