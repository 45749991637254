<script lang="ts">
  export let id: string;
  export let label: string;
  export let value: string;
  export let info = "";
  export let error = "";
  export let placeholder = "";
  export let testID: string | undefined = undefined;
</script>

<div class="celebratix-widget-text-base-bold celebratix-widget-container">
  <label class="celebratix-widget-text-s celebratix-widget-label" for={id}
    >{label}</label
  >
  <input
    class="celebratix-widget-text-base-bold"
    type="email"
    {placeholder}
    {id}
    data-testid={testID}
    bind:value
  />
  {#if error}
    <div class="celebratix-widget-text-s celebratix-widget-error">{error}</div>
  {:else if info}
    <div class="celebratix-widget-text-s celebratix-widget-info">{info}</div>
  {/if}
</div>

<style>
  .celebratix-widget-label {
    width: fit-content;
    padding-bottom: 4px;
  }
  .celebratix-widget-container {
    display: flex;
    flex-direction: column;
  }
  .celebratix-widget-info {
    padding-top: 4px;
    color: #a8a29e;
  }
  .celebratix-widget-error {
    padding-top: 4px;
    color: #ff5e7c;
  }
</style>
