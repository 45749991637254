import { queryOptions } from "@tanstack/svelte-query";
import {
  countriesApiV1,
  eventsApiV2,
  marketplaceApiV1,
  ordersApiV1,
  trackingLinksApiV2,
} from "./instances";

// TODO: Make pagination
// Could also re-add prefetching of the events if necessary
export const queryEvents = ({
  channel,
  search,
}: {
  channel?: string;
  search?: string;
}) => {
  return queryOptions({
    queryKey: ["events", { channel }, { search }],
    queryFn: () =>
      eventsApiV2.getV2ConsumersEvents({
        channel,
        search,
        pageSize: 100,
      }),
    staleTime: 1000 * 60 * 2,
    gcTime: Infinity,
  });
};

export const queryEvent = (id: number | undefined, channel?: string) => {
  return queryOptions({
    queryKey: ["events", { id }, { channel }],
    queryFn: () => eventsApiV2.getV2ConsumersEventsById({ id: id!, channel }),
    enabled: !!id,
    staleTime: 1000 * 60 * 2,
    gcTime: Infinity,
  });
};

export const queryMarketplace = (id: number | undefined) => {
  return queryOptions({
    queryKey: ["marketplace", { id }],
    queryFn: () =>
      marketplaceApiV1.getUserMarketplaceByEventId({ eventId: id! }),
    enabled: !!id,
  });
};

export const queryCountries = () => {
  return queryOptions({
    queryKey: ["countries"],
    queryFn: () => countriesApiV1.getCountries(),
    staleTime: 1000 * 60 * 60,
    gcTime: Infinity,
  });
};

export const queryListing = (id: string | undefined) => {
  return queryOptions({
    queryKey: ["listings", { id }],
    queryFn: () =>
      marketplaceApiV1.getUserMarketplaceByListingId({
        listingId: id!,
      }),
    enabled: !!id,
  });
};

export const queryOrder = (id: string | undefined) => {
  return queryOptions({
    queryKey: ["orders", { id }],
    queryFn: () => ordersApiV1.getUserOrdersByOrderId({ orderId: id! }),
    enabled: !!id,
    staleTime: 1000 * 60 * 2,
    gcTime: Infinity,
  });
};

export const queryChannelFromTracking = (slug: string | null) => {
  return queryOptions({
    queryKey: ["channels", { slug }],
    queryFn: () =>
      trackingLinksApiV2.getV2ConsumersTrackingLinksByLink({ link: slug! }),
    staleTime: 0,
    enabled: !!slug,
  });
};

export const queryCouponTickets = (
  id: number | undefined,
  couponCode: string | undefined,
) => {
  return queryOptions({
    queryKey: ["couponTickets", { id }, { couponCode }],
    queryFn: () =>
      eventsApiV2.getV2ConsumersEventsByIdCouponsByCouponCodeTicketTypes({
        couponCode: couponCode!,
        id: id!,
      }),
    staleTime: 1000 * 60 * 2,
    gcTime: Infinity,
    enabled: !!couponCode && !!id,
  });
};
