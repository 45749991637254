import { get } from "svelte/store";
import { eventFacebookPixelId, selectedEventId, userId } from "../store/global";
import type { TrackEventRequest } from "./generated/v2";
import { params } from "../store/params";
import { track } from "../utils/utils";
import { createMutation } from "@tanstack/svelte-query";
import { analyticsApiV2 } from "./instances";

export const useTrackEvent = () => {
  interface trackEventOptions {
    trackingEventName: string;
    contentCategory?: string | null;
    contentIds?: string[] | null;
    contentName?: string | null;
    contentType?: string | null;
    currency?: string | null;
    value?: number | null;
  }
  const trackFacebookEvent = (trackEventOptions: trackEventOptions) => {
    const selectedEventIdValue = get(selectedEventId);
    const trackEventRequest = <Omit<TrackEventRequest, "facebookPixelId">>{
      trackingEventId: Date.now().toString(),
      trackingEventName: trackEventOptions.trackingEventName,
      eventId: selectedEventIdValue ?? null,
      businessId: get(params).business ?? null,
      userId: get(userId) ?? null,
      contentCategory: trackEventOptions.contentCategory ?? null,
      contentIds: trackEventOptions.contentIds ?? null,
      contentName: trackEventOptions.contentName ?? null,
      contentType: selectedEventIdValue ? "event" : null,
      currency: trackEventOptions.currency ?? null,
      value: trackEventOptions.value ?? null,
    };

    const eventPixel = get(eventFacebookPixelId);
    const businessPixel = get(params).pixel;

    if (eventPixel) {
      const data = {
        ...trackEventRequest,
        businessId: null,
        facebookPixelId: eventPixel,
        trackingEventId: trackEventRequest.trackingEventId + "-event",
      };

      // Pixel tracking
      track(data);

      // Conversion API tracking
      if (trackEventRequest.eventId) {
        get(analyticsPost).mutate(data);
      }
    }

    if (businessPixel) {
      const data = {
        ...trackEventRequest,
        eventId: null,
        facebookPixelId: businessPixel,
        trackingEventId: trackEventRequest.trackingEventId + "-business",
      };

      // Pixel tracking
      track(data);

      // Conversion API tracking
      if (trackEventRequest.businessId) {
        get(analyticsPost).mutate(data);
      }
    }
  };

  const analyticsPost = createMutation({
    mutationFn: (trackEventRequest: TrackEventRequest) =>
      analyticsApiV2.postV2BusinessAnalytics({
        requestBody: trackEventRequest,
      }),
  });

  return { trackFacebookEvent };
};
