<script lang="ts">
  import { onDestroy } from "svelte";
  import { page } from "../store/navigation";
  import { _ } from "svelte-i18n";

  let title = "";

  const unsubscribe = page.subscribe((value) => {
    const currentPage = value.at(-1);
    title = currentPage?.title ?? "";
  });

  onDestroy(unsubscribe);
</script>

<div class="celebratix-widget-container">
  <div class="celebratix-widget-h2">
    {title ? $_(`header.${title}`) : ""}
  </div>
  <div class="celebratix-widget-text-base celebratix-widget-paginator">
    {$page.at($page.length - 1)?.index ?? 0}/4
  </div>
</div>

<style>
  .celebratix-widget-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    max-width: 640px;
  }
  .celebratix-widget-paginator {
    color: #fafaf9;
  }
</style>
