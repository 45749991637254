<script lang="ts">
  import { Icon, ChevronRight } from "svelte-hero-icons";
  import { selectedEventId } from "../store/global";
  import { addPage } from "../store/navigation";
  import { locale } from "svelte-i18n";
  import { displayDate } from "../utils/utils";
  import { basePath } from "../api/instances";
  import type { GetConsumerEventsDto } from "../api/generated/v2";

  // Params
  export let event: GetConsumerEventsDto | undefined;
  export let disabled = false;

  const handleClick = () => {
    addPage(
      { page: "TicketsScreen", title: "tickets", index: 2 },
      `eventId=${event?.id}`,
    );
    selectedEventId.set(event?.id);
  };
</script>

<button
  class="celebratix-widget-container"
  style={disabled ? "cursor: auto" : "cursor: pointer"}
  {disabled}
  on:click={() => handleClick()}
  data-testid="event-card"
>
  <div class="celebratix-widget-left-container">
    <img
      src={`${basePath}/${event?.image?.path}?width=480&format=webp`}
      height="120px"
      width="120px"
      class="celebratix-widget-image"
      alt="event"
    />
    <div class="celebratix-widget-details">
      <div class="celebratix-widget-h4">{event?.name}</div>
      <div class="celebratix-widget-text-s-bold celebratix-widget-date">
        {displayDate(event?.startDate, $locale, {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
        -
        {displayDate(event?.endDate, $locale, {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </div>
      <div class="celebratix-widget-text-s celebratix-widget-provider">
        {event?.location}{event?.city ? ` - ${event.city}` : ""}
      </div>
    </div>
  </div>
  {#if !disabled}
    <div class="celebratix-widget-arrow"><Icon src={ChevronRight} mini /></div>
  {/if}
</button>

<style>
  .celebratix-widget-image {
    object-fit: cover;
    aspect-ratio: 1;
    min-width: 120px;
    min-height: 120px;
    object-position: center;
    border-radius: 4px;
  }
  .celebratix-widget-container {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
    padding: 20px 0 20px 0;
  }
  .celebratix-widget-left-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 16px;
  }
  .celebratix-widget-details {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .celebratix-widget-date {
    color: #a8a29e;
  }
  .celebratix-widget-provider {
    color: #a8a29e;
  }
  .celebratix-widget-arrow {
    color: #a3f3ef;
    height: 24px;
    width: 24px;
    min-height: 24px;
    min-width: 24px;
  }
</style>
