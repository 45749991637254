<script lang="ts">
  import Card from "../components/Card.svelte";
  import { _ } from "svelte-i18n";
  import { addPage, page } from "../store/navigation";
  import { onMount } from "svelte";
  import { pendingPayment, selectedEventId } from "../store/global";
  import { useTrackEvent } from "../api/mutationHooks";

  const { trackFacebookEvent } = useTrackEvent();

  // Clear pending payment on mount to cancel potential order on redirect
  // And track view content event
  onMount(() => {
    pendingPayment.set(false);

    trackFacebookEvent({
      trackingEventName: "ViewContent",
      contentCategory: $page.at(-1)?.page ?? null,
      contentIds: $selectedEventId ? [$selectedEventId.toString()] : null,
      contentType: $selectedEventId ? "event" : null,
    });
  });
</script>

<Card>
  <div class="celebratix-widget-container">
    <div class="celebratix-widget-inner-container">
      <div class="celebratix-widget-h1">{$_("payment_fail_screen.title")}</div>
      <div class="celebratix-widget-text-base-bold">
        {$_("payment_fail_screen.description")}
      </div>
      <button
        class="button"
        on:click={() =>
          addPage({
            page: "EventsScreen",
            title: "events",
            index: 1,
          })}
      >
        <div class="celebratix-widget-text-base-bold">
          {$_("payment_fail_screen.button")}
        </div>
      </button>
    </div>
    <img src={`${import.meta.env.VITE_CDN_URL}/icons/clbtx.svg"`} alt="CLBTX" />
  </div>
</Card>

<style>
  .celebratix-widget-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 40px;
    padding: 40px 32px 40px 32px;
  }
  .celebratix-widget-inner-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
</style>
