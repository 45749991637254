<script lang="ts">
  import { createQuery } from "@tanstack/svelte-query";
  import {
    channelSlug,
    eventFacebookPixelId,
    selectedEventId,
    selectedListing,
    selectedPaymentMethod,
    selectedTickets,
  } from "../store/global";
  import { _ } from "svelte-i18n";
  import { queryEvent, queryListing } from "../api/queryOptions";
  import { params } from "../store/params";
  import { onDestroy } from "svelte";
  import { displayPrice } from "../utils/utils";
  import type {
    GetConsumerEventDetailsCommonPaymentFeeDto,
    GetConsumerEventDetailsPaymentMethodFeeDto,
  } from "../api/generated/v2";

  // State
  let totalPrice = 0;
  let totalQuantity = 0;
  let selectedPaymentFee:
    | GetConsumerEventDetailsCommonPaymentFeeDto
    | GetConsumerEventDetailsPaymentMethodFeeDto
    | null
    | undefined = null;

  const eventState = createQuery(
    queryEvent($selectedEventId, $channelSlug ?? $params.channel ?? undefined),
  );

  $: {
    // Calculate the selected payment fee
    const unsubscribeEvent = eventState.subscribe(({ data }) => {
      const paymentFees = data?.data?.paymentFees;
      if (paymentFees) {
        if (paymentFees.usesCommonPaymentFee) {
          selectedPaymentFee = paymentFees.commonPaymentFee;
        } else {
          selectedPaymentFee = paymentFees.paymentMethodFees?.find(
            (fee) =>
              fee.stripeName.toLowerCase() ===
              $selectedPaymentMethod?.toLowerCase(),
          );
        }
      }

      if (data?.data?.facebookPixelId) {
        eventFacebookPixelId.set(data.data.facebookPixelId);
      }
    });

    onDestroy(() => {
      unsubscribeEvent();
    });
  }

  const listingState = createQuery(queryListing($selectedListing?.id));

  const unsubscribeSelectedTickets = selectedTickets.subscribe((value) => {
    if (!$selectedEventId) return;

    // Each event can only have one selected ticket
    const selectedTicket = value[$selectedEventId];
    if (!selectedTicket) return;
    totalQuantity = selectedTicket.quantity;
    totalPrice = selectedTicket.total;
  });

  onDestroy(() => {
    unsubscribeSelectedTickets();
  });
</script>

<div class="celebratix-widget-container">
  <div class="celebratix-widget-ticket-container">
    <div class="celebratix-widget-text-s-bold">
      {$_("payment_screen.tickets")}
    </div>
    <div class="celebratix-widget-text-l-bold">
      {#if $listingState.isSuccess || $eventState.isSuccess}
        {totalQuantity}
      {:else}
        {0}
      {/if}
    </div>
  </div>
  <div class="celebratix-widget-total-container">
    <div class="celebratix-widget-text-s-bold">
      {$_("payment_screen.total")}
    </div>
    <div class="celebratix-widget-text-l-bold">
      {#if $listingState.isSuccess || $eventState.isSuccess}
        {displayPrice(
          totalPrice,
          $eventState.data?.data?.currency,
          selectedPaymentFee,
        )}
      {:else}
        {0}
      {/if}
      <span class="celebratix-widget-text-s"
        >{$_("payment_screen.payment_costs")}</span
      >
    </div>
  </div>
</div>

<style>
  .celebratix-widget-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .celebratix-widget-ticket-container {
    display: flex;
    flex-direction: column;
    width: 80px;
  }
  .celebratix-widget-total-container {
    display: flex;
    flex-direction: column;
  }
</style>
