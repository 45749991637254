import Root from "./navigation/Root.svelte";
import * as Sentry from "@sentry/svelte";

// eslint-disable-next-line no-constant-condition
if (import.meta.env.VITE_SENTRY_DNS) {
  const allowUrls: (string | RegExp)[] = [
    "https://api.celebratix.io",
    "https://api-staging.celebratix.io",
    "https://cdn.celebratix.io",
    "https://cdn-staging.celebratix.io",
  ];
  // Uncomment if you want to debug sentry locally
  // allowUrls.push(/https?:\/\/localhost:?\d*/);
  // allowUrls.push(/https?:\/\/127\.0\.0\.1:?\d*/);
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DNS,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: allowUrls,
    allowUrls,
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,
    environment: import.meta.env.MODE,
    // Uncomment if you want to debug sentry
    // debug: true
  });
}

const widgetDivs = document.querySelectorAll("#celebratix-ticket-widget");

widgetDivs.forEach((div) => {
  new Root({
    target: div,
    props: {
      primary: (div as HTMLDivElement).dataset.primary,
      location: (div as HTMLDivElement).dataset.location,
      tos: (div as HTMLDivElement).dataset.tos,
      business: (div as HTMLDivElement).dataset.business,
      pixel: (div as HTMLDivElement).dataset.pixel,
      channel: (div as HTMLDivElement).dataset.channel,
      tracking: (div as HTMLDivElement).dataset.tracking,
      noScroll: (div as HTMLDivElement).dataset.no_scroll,
    },
  });
});
