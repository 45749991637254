<script lang="ts">
  import { createQuery } from "@tanstack/svelte-query";
  import {
    channelSlug,
    eventFacebookPixelId,
    selectedEventId,
    selectedTickets,
  } from "../store/global";
  import { _ } from "svelte-i18n";
  import { queryEvent } from "../api/queryOptions";
  import type { GetConsumerEventDetailsCurrencyDto } from "../api/generated/v2";
  import { params } from "../store/params";
  import { onDestroy } from "svelte";
  import {
    displayFractionAsPercentage,
    displayPaymentFee,
    displayPrice,
  } from "../utils/utils";

  // Params
  export let currency: GetConsumerEventDetailsCurrencyDto | undefined | null;
  export let disabled = false;
  export let onClick: (() => void) | undefined = undefined;

  // State
  let totalPrice = 0;
  let totalQuantity = 0;

  const eventState = createQuery(
    queryEvent($selectedEventId, $channelSlug ?? $params.channel ?? undefined),
  );

  const unsubscribeEvent = eventState.subscribe(({ data }) => {
    if (data?.data?.facebookPixelId) {
      eventFacebookPixelId.set(data.data.facebookPixelId);
    }
  });

  const unsubscribeSelectedTickets = selectedTickets.subscribe((value) => {
    if (!$selectedEventId) return;

    // Each event can only have one selected ticket
    const selectedTicket = value[$selectedEventId];
    if (!selectedTicket) return;
    totalQuantity = selectedTicket.quantity;
    totalPrice = selectedTicket.total;
  });

  onDestroy(() => {
    unsubscribeEvent();
    unsubscribeSelectedTickets();
  });
</script>

<div class="celebratix-widget-container">
  <div class="celebratix-widget-left-container">
    <div class="celebratix-widget-ticket-container">
      <div class="celebratix-widget-text-s-bold">
        {$_("tickets_screen.tickets")}
      </div>
      <div class="celebratix-widget-text-base-bold">{totalQuantity}</div>
    </div>
    <div class="celebratix-widget-total-container">
      <div class="celebratix-widget-text-s-bold">
        {$_("tickets_screen.total")}
      </div>
      <div class="celebratix-widget-text-base-bold">
        {displayPrice(
          totalPrice,
          currency,
          // If a common payment fee is selected, use it
          // If multiple payment fees are possible,
          // the result is not correct since we don't know what the payment method is yet
          $eventState.data?.data?.paymentFees.commonPaymentFee,
        )}
      </div>
      <div class="celebratix-widget-text-xs">
        {$eventState.data?.data?.paymentFees.commonPaymentFee
          ? `${$_("tickets_screen.payment_costs_1")} ${displayPaymentFee(
              totalPrice,
              currency,
              $eventState.data?.data?.paymentFees.commonPaymentFee,
            )} (${displayFractionAsPercentage(
              $eventState.data?.data?.paymentFees.commonPaymentFee.feeFraction,
            )}) ${$_("tickets_screen.payment_costs_2")}`
          : $_("tickets_screen.service_costs")}
      </div>
    </div>
  </div>
  <button
    on:click={onClick}
    disabled={disabled || totalQuantity <= 0}
    data-testid="order-button"
  >
    <div class="celebratix-widget-text-base-bold">
      {$_("tickets_screen.order")}
    </div>
  </button>
</div>

<style>
  .celebratix-widget-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 32px 20px 32px;
  }
  .celebratix-widget-left-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .celebratix-widget-ticket-container {
    display: flex;
    flex-direction: column;
    width: 80px;
  }
  .celebratix-widget-total-container {
    display: flex;
    flex-direction: column;
  }
  button {
    width: 48px;
    height: 48px;
    color: #1c1917;
    padding: 0px 20px 0px 20px;
  }
  button:disabled {
    background-color: #d0cfce;
    color: #a8a29e;
    cursor: auto;
  }
</style>
