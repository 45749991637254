<script lang="ts">
  export let id: string;
  export let label: string | undefined = undefined;
  export let value: string;
  export let info = "";
  export let error = "";
  export let success = "";
  export let placeholder = "";
  export let testID: string | undefined = undefined;
</script>

<div class="celebratix-widget-text-base-bold celebratix-widget-container">
  {#if label}
    <label class="celebratix-widget-text-s celebratix-widget-label" for={id}
      >{label}</label
    >
  {/if}
  <input
    class="celebratix-widget-text-base-bold"
    type="text"
    {placeholder}
    {id}
    bind:value
    data-testid={testID}
  />
  {#if error}
    <div class="celebratix-widget-text-s celebratix-widget-error">{error}</div>
  {:else if success}
    <div class="celebratix-widget-text-s celebratix-widget-success">
      {success}
    </div>
  {:else if info}
    <div class="celebratix-widget-text-s celebratix-widget-info">{info}</div>
  {/if}
</div>

<style>
  .celebratix-widget-label {
    width: fit-content;
    padding-bottom: 4px;
  }
  .celebratix-widget-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .celebratix-widget-info {
    padding-top: 4px;
    color: #a8a29e;
  }
  .celebratix-widget-error {
    padding-top: 4px;
    color: #ff5e7c;
  }
  .celebratix-widget-success {
    padding-top: 4px;
    color: #189435;
  }
  input {
    color: var(--color, #fafaf9);
    background-color: var(--background-color, "transparent");
  }
  input:focus {
    border: 1px solid var(--color, #fafaf9);
  }
  input:hover {
    border: 1px solid var(--color, #fafaf9);
  }
</style>
