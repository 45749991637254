<script lang="ts">
  import { createQuery } from "@tanstack/svelte-query";
  import {
    channelSlug,
    eventFacebookPixelId,
    selectedEventId,
  } from "../store/global";
  import MarketplaceSelector from "./MarketplaceSelector.svelte";
  import { Icon, ChevronLeft } from "svelte-hero-icons";
  import { _ } from "svelte-i18n";
  import { queryEvent, queryMarketplace } from "../api/queryOptions";
  import { params } from "../store/params";
  import { onDestroy } from "svelte";

  // Params
  export let tryMarketplace: boolean;
  export let tosAccepted: boolean;

  const marketplaceState = createQuery(queryMarketplace($selectedEventId));
  const eventState = createQuery(
    queryEvent($selectedEventId, $channelSlug ?? $params.channel ?? undefined),
  );

  const unsubscribe = eventState.subscribe(({ data }) => {
    if (data?.data?.facebookPixelId) {
      eventFacebookPixelId.set(data.data.facebookPixelId);
    }
  });

  onDestroy(unsubscribe);
</script>

<div>
  <div class="celebratix-widget-header">
    <button on:click={() => (tryMarketplace = false)}>
      <div class="celebratix-widget-icon">
        <Icon src={ChevronLeft} mini />
      </div>
    </button>
    <div class="celebratix-widget-text-l-bold">
      {$_("tickets_screen.marketplace")}
    </div>
  </div>

  <!-- Check if there are any listings -->
  {#if !$marketplaceState.data?.list || $marketplaceState.data.list.length === 0}
    <div class="celebratix-widget-empty">
      <div class="celebratix-widget-text-base">
        {$_("tickets_screen.no_offers")}
      </div>
    </div>
  {:else}
    <!-- List all ticket types -->
    {#each $eventState.data?.data?.tickets ?? [] as ticket}
      <!-- Check if there are any listing of current ticket type -->
      {#if $marketplaceState.data?.list.some((marketplaceListing) => marketplaceListing.ticketTypeId === ticket.id)}
        <MarketplaceSelector
          {tosAccepted}
          {ticket}
          listings={$marketplaceState.data.list.filter(
            (listing) => listing.ticketTypeId === ticket.id,
          )}
        />
      {/if}
    {/each}
  {/if}
</div>

<style>
  button {
    background-color: #dddddc;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .celebratix-widget-icon {
    color: #1c1917;
    height: 24px;
    width: 24px;
  }
  .celebratix-widget-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 20px 32px;
    background-color: #f5f5f4;
  }
  .celebratix-widget-empty {
    padding: 20px 32px;
  }
</style>
