<script lang="ts">
  import { _ } from "svelte-i18n";

  export let label: string;
  export let options: string[];
  export let userSelected = options[0];
  export let info = "";
  export let error = "";
</script>

<div class="celebratix-widget-text-base-bold celebratix-widget-container">
  <div class="celebratix-widget-text-s">{label}</div>
  <div role="radiogroup" class="celebratix-widget-radio-container">
    {#each options as option}
      <div class="celebratix-widget-option-container">
        <input
          type="radio"
          id={option}
          name={option}
          value={option}
          bind:group={userSelected}
        />
        <label class="celebratix-widget-label" for={option}>
          {$_(`register_screen.${option.toLowerCase()}`)}
        </label>
      </div>
    {/each}
  </div>
  {#if error}
    <div class="celebratix-widget-text-s celebratix-widget-error">{error}</div>
  {:else if info}
    <div class="celebratix-widget-text-s celebratix-widget-info">{info}</div>
  {/if}
</div>

<style>
  .celebratix-widget-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .celebratix-widget-radio-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }
  .celebratix-widget-option-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .celebratix-widget-label {
    padding-left: 8px;
  }
  .celebratix-widget-info {
    padding-top: 4px;
    color: #a8a29e;
  }
  .celebratix-widget-error {
    padding-top: 4px;
    color: #ff5e7c;
  }
</style>
