<script lang="ts">
  import { createMutation, createQuery } from "@tanstack/svelte-query";
  import Card from "../components/Card.svelte";
  import DateInput from "../components/DateInput.svelte";
  import EmailInput from "../components/EmailInput.svelte";
  import RadioInput from "../components/RadioInput.svelte";
  import TextInput from "../components/TextInput.svelte";
  import { addPage, page } from "../store/navigation";
  import { _ } from "svelte-i18n";
  import { get } from "svelte/store";
  import {
    channelSlug,
    couponCode,
    currentOrder,
    orderId,
    paymentDetails,
    selectedEventId,
    selectedListing,
    selectedTickets,
    trackingSlug,
    userEmail,
    userFullName,
  } from "../store/global";
  import { onDestroy, onMount } from "svelte";
  import type { Gender } from "../api/generated/v1";
  import { useTrackEvent } from "../api/mutationHooks";
  import { ordersApiV2 } from "../api/instances";
  import { params } from "../store/params";
  import { queryEvent } from "../api/queryOptions";
  import type {
    CreatePrimaryOrderDto,
    CheckboxBusinessOrderFieldValueRequest,
    ShortTextBusinessOrderFieldValueRequest,
    DatePickerBusinessOrderFieldValueRequest,
    BusinessOrderFieldLabelDto,
    PostV2UsersOrdersPrimaryAsGuestData,
    PostV2UsersOrdersResaleAsGuestData,
    CreateResaleOrderAsGuestDto,
  } from "../api/generated/v2";
  import CheckboxInput from "../components/CheckboxInput.svelte";
  import { locale } from "svelte-i18n";
  const t = get(_);

  interface CustomFieldCommon {
    labels: BusinessOrderFieldLabelDto[];
    required: boolean;
    info: string;
    error: string;
  }

  interface Inputs {
    firstName: { value: string; info: string; error: string };
    lastName: { value: string; info: string; error: string };
    dateOfBirth: { value: string; info: string; error: string };
    gender: { value: Gender | ""; info: string; error: string };
    email: { value: string; info: string; error: string };
    confirmEmail: { value: string; info: string; error: string };
    city: { value: string; info: string; error: string };
    businessOrderFields?: (
      | (CheckboxBusinessOrderFieldValueRequest &
          CustomFieldCommon & {
            type: "Checkbox";
          })
      | (ShortTextBusinessOrderFieldValueRequest &
          CustomFieldCommon & {
            type: "ShortText";
          })
      | (DatePickerBusinessOrderFieldValueRequest &
          CustomFieldCommon & {
            type: "DatePicker";
          })
    )[];
  }

  // State
  let inputs: Inputs = {
    firstName: { value: "", info: "", error: "" },
    lastName: { value: "", info: "", error: "" },
    dateOfBirth: { value: "", info: "", error: "" },
    gender: { value: "", info: "", error: "" },
    email: { value: "", info: "", error: "" },
    confirmEmail: { value: "", info: "", error: "" },
    city: { value: "", info: "", error: "" },
  };

  const { trackFacebookEvent } = useTrackEvent();

  const eventState = createQuery(
    queryEvent($selectedEventId, $channelSlug ?? $params.channel ?? undefined),
  );

  onMount(() => {
    // Track view content event
    trackFacebookEvent({
      trackingEventName: "ViewContent",
      contentCategory: $page.at(-1)?.page ?? null,
      contentIds: $selectedEventId ? [$selectedEventId.toString()] : null,
      contentType: $selectedEventId ? "event" : null,
    });
  });

  // Used to translate errors if users changes language
  const translateErrors = () => {
    let ageLimit = $eventState.data?.data?.ageLimit ?? 10;

    if (inputs["firstName"].error) {
      inputs["firstName"].error = t("register_screen.first_name_error");
    }
    if (inputs["lastName"].error) {
      inputs["lastName"].error = t("register_screen.last_name_error");
    }
    if (inputs["city"].error) {
      inputs["city"].error = t("register_screen.city_error");
    }
    if (inputs["dateOfBirth"].error) {
      if (!inputs["dateOfBirth"].value) {
        inputs["dateOfBirth"].error = t(
          "register_screen.date_of_birth_error_1",
        );
      } else {
        inputs["dateOfBirth"].error = t(
          "register_screen.date_of_birth_error_2",
          { values: { eventAgeLimit: ageLimit } },
        );
      }
    }
    if (inputs["gender"].error) {
      inputs["gender"].error = t("register_screen.gender_error");
    }
    if (inputs["email"].error) {
      if (!inputs["email"].value) {
        inputs["email"].error = t("register_screen.email_error_1");
      } else {
        inputs["email"].error = t("register_screen.email_error_2");
      }
    }
    if (inputs["confirmEmail"].error) {
      if (!inputs["confirmEmail"].value) {
        inputs["confirmEmail"].error = t("register_screen.email_error_1");
      } else {
        inputs["confirmEmail"].error = t("register_screen.email_error_3");
      }
    }
  };

  const unsubscribeTranslations = _.subscribe(() => {
    inputs["email"].info = t("register_screen.email_info");
    translateErrors();
  });

  const unsubscribeEvent = eventState.subscribe(({ data }) => {
    if (data?.data?.businessOrderFields) {
      // Extract data needed for custom field input
      inputs.businessOrderFields = data.data.businessOrderFields.map(
        (field) => {
          const shared = {
            businessOrderFieldId: field.id,
            type: field.type,
            labels: field.labels,
            required: field.required,
            info: "",
            error: "",
          };
          if (field.type === "Checkbox") {
            return {
              ...shared,
              type: field.type,
              value: false,
            };
          } else {
            return {
              ...shared,
              type: field.type,
              value: "",
            };
          }
        },
      );
    }
  });

  // Executed after order success callbacks
  const postOrderHandler = (
    data: CreatePrimaryOrderDto | CreateResaleOrderAsGuestDto,
  ) => {
    const selectedTicket = $selectedEventId
      ? $selectedTickets[$selectedEventId]
      : undefined;
    if (!selectedTicket && !$selectedListing) {
      throw new Error("No selected ticket or listing on postOrderHandler");
    }
    if (selectedTicket) {
      trackFacebookEvent({
        trackingEventName: "Purchase",
        contentCategory: $eventState.data?.data?.name ?? null,
        contentIds: [selectedTicket.id],
        contentName: selectedTicket.name,
        contentType: "ticketType",
        currency: $eventState.data?.data?.currency?.code ?? null,
        value: selectedTicket.total,
      });
    }
    currentOrder.set(data);
    orderId.set(data.orderId);

    userEmail.set(inputs["email"].value);
    userFullName.set(
      `${inputs["firstName"].value} ${inputs["lastName"].value}`,
    );

    addPage({
      page: "PaymentScreen",
      title: "payment",
      index: 4,
    });
  };

  const createPrimaryOrder = createMutation({
    mutationFn: (request: PostV2UsersOrdersPrimaryAsGuestData) =>
      ordersApiV2.postV2UsersOrdersPrimaryAsGuest(request),
    onSuccess: ({ data }) => {
      // Only nullable if the request is not successful
      if (!data) return;

      postOrderHandler(data);
    },
    onError: (error) => {
      // TODO: some popup would be nice here
      console.error("Error creating primary order", error);
    },
  });

  const createResaleOrder = createMutation({
    mutationFn: (request: PostV2UsersOrdersResaleAsGuestData) =>
      ordersApiV2.postV2UsersOrdersResaleAsGuest(request),
    onSuccess: ({ data }) => {
      // Only nullable if the request is not successful
      if (!data) return;

      postOrderHandler(data);
    },
    onError: (error) => {
      // TODO: some popup would be nice here
      console.error("Error creating marketplace order", error);
    },
  });

  const validateInputs = () => {
    let isValid = true;
    // First name
    if (!inputs["firstName"].value) {
      inputs["firstName"].error = t("register_screen.first_name_error");
      isValid = false;
    } else {
      inputs["firstName"].error = "";
    }
    // Last name
    if (!inputs["lastName"].value) {
      inputs["lastName"].error = t("register_screen.last_name_error");
      isValid = false;
    } else {
      inputs["lastName"].error = "";
    }

    //City
    if (!inputs["city"].value) {
      inputs["city"].error = t("register_screen.city_error");
      isValid = false;
    } else {
      inputs["city"].error = "";
    }

    // Date of birth
    let ageLimit = $eventState.data?.data?.ageLimit ?? 10;
    let eventStartDate = $eventState.data?.data?.startDate
      ? new Date($eventState.data?.data?.startDate)
      : new Date();
    let userAge = calculateAge(inputs["dateOfBirth"].value, eventStartDate);

    if (!inputs["dateOfBirth"].value) {
      inputs["dateOfBirth"].error = t("register_screen.date_of_birth_error_1");
      isValid = false;
    } else if (userAge < ageLimit) {
      inputs["dateOfBirth"].error = t("register_screen.date_of_birth_error_2", {
        values: { eventAgeLimit: ageLimit },
      });
      isValid = false;
    } else {
      inputs["dateOfBirth"].error = "";
    }

    // Gender
    if (!inputs["gender"].value) {
      inputs["gender"].error = t("register_screen.gender_error");
      isValid = false;
    } else {
      inputs["gender"].error = "";
    }

    // Email
    if (!inputs["email"].value) {
      inputs["email"].error = t("register_screen.email_error_1");
      isValid = false;
    } else if (!/^(\S+@\S+)\.\S+/.test(inputs["email"].value)) {
      inputs["email"].error = t("register_screen.email_error_2");
      isValid = false;
    } else {
      inputs["email"].error = "";
    }

    // Email confirm
    if (!inputs["confirmEmail"].value) {
      inputs["confirmEmail"].error = t("register_screen.email_error_1");
      isValid = false;
    } else if (
      inputs["confirmEmail"].value.trim().toLowerCase() !==
      inputs["email"].value.trim().toLowerCase()
    ) {
      inputs["confirmEmail"].error = t("register_screen.email_error_3");
      isValid = false;
    } else {
      inputs["confirmEmail"].error = "";
    }

    // TODO: Add validation from backend (e.g. DatePicker)
    // Custom fields
    inputs.businessOrderFields?.forEach((businessOrderField) => {
      if (!businessOrderField.value && businessOrderField.required) {
        businessOrderField.error = t("register_screen.custom_field_error");
        isValid = false;
      } else {
        businessOrderField.error = "";
      }
    });

    return isValid;
  };

  const extractLabel = (labels: BusinessOrderFieldLabelDto[]) => {
    let label = labels.find((label) => label.languageCode === $locale);

    if (!label) {
      label = labels.find((label) => label.defaultLabel);
    }

    return label?.label ?? "";
  };

  const calculateAge = (dateString: string, eventDateString: Date) => {
    var birthDate = new Date(dateString);
    // Add one day to the event date
    eventDateString.setDate(eventDateString.getDate() + 1);

    var age = eventDateString.getFullYear() - birthDate.getFullYear();
    var m = eventDateString.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && eventDateString.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleSubmit = () => {
    if (!validateInputs()) return;
    if (!$selectedEventId) return;

    let dateOfBirth = "2000-01-10T23:00:00.000Z";
    try {
      dateOfBirth = new Date(inputs["dateOfBirth"].value).toISOString();
    } catch {
      console.error(`WARNING: Could not parse date of birth: ${dateOfBirth}`);
    }

    // Create secondary order and signin/signup
    if ($selectedListing) {
      $createResaleOrder.mutate({
        requestBody: {
          email: inputs["email"].value,
          firstName: inputs["firstName"].value,
          lastName: inputs["lastName"].value,
          gender: inputs["gender"].value as Gender,
          dateOfBirth: dateOfBirth,
          resaleListingId: $selectedListing.id,
          city: inputs["city"].value,
        },
      });
      paymentDetails.set({
        ticketName: $selectedListing.ticketTypeName,
        ticketPrice: $selectedListing.listingPrice,
        orderAmount: $selectedListing.listingPrice,
        orderCurrency: $selectedListing.currency,
        ticketQuantity: 1,
        ticketServiceFee: 0,
      });
    } else if ($selectedTickets[$selectedEventId]) {
      let ticket = $selectedTickets[$selectedEventId];
      paymentDetails.set({
        ticketName: ticket.name,
        ticketPrice: ticket.price,
        orderAmount: ticket.total,
        orderCurrency: $eventState.data?.data?.currency,
        ticketQuantity: ticket.quantity,
        ticketServiceFee: ticket.serviceFee,
      });
      // Create primary order and signin/signup
      $createPrimaryOrder.mutate({
        requestBody: {
          email: inputs["email"].value,
          firstName: inputs["firstName"].value,
          lastName: inputs["lastName"].value,
          gender: inputs["gender"].value as Gender,
          dateOfBirth: dateOfBirth,
          ticketTypeId: ticket.id,
          ticketQuantity: ticket.quantity,
          trackingLinkCode: $trackingSlug,
          channelSlug: $channelSlug ?? $params.channel ?? null,
          fromCelebratix: !$trackingSlug && !$channelSlug && !$params.channel,
          couponCode: $couponCode,
          city: inputs["city"].value,
          businessOrderFieldValues: inputs.businessOrderFields ?? null,
        },
      });
    } else {
      throw new Error("No selected ticket or listing on submit");
    }
  };

  onDestroy(() => {
    unsubscribeTranslations();
    unsubscribeEvent();
  });
</script>

<Card loading={$createResaleOrder.isPending || $createPrimaryOrder.isPending}>
  <div class="celebratix-widget-container">
    <div class="celebratix-widget-header-container">
      <div class="celebratix-widget-h3">{$_("register_screen.title")}</div>
      <div class="celebratix-widget-text-base">
        {$_("register_screen.description")}
      </div>
    </div>
    <div class="celebratix-widget-form-container">
      <EmailInput
        id="email"
        label={$_("register_screen.email")}
        placeholder={$_("register_screen.email_placeholder")}
        bind:value={inputs["email"].value}
        info={inputs["email"].info}
        error={inputs["email"].error}
        testID="email-input"
      />
      <EmailInput
        id="email_confirm"
        label={$_("register_screen.email_confirm")}
        placeholder={$_("register_screen.email_placeholder")}
        bind:value={inputs["confirmEmail"].value}
        info={inputs["confirmEmail"].info}
        error={inputs["confirmEmail"].error}
        testID="confirm-email-input"
      />
      <TextInput
        id="first_name"
        label={$_("register_screen.first_name")}
        placeholder={$_("register_screen.first_name_placeholder")}
        bind:value={inputs["firstName"].value}
        info={inputs["firstName"].info}
        error={inputs["firstName"].error}
        testID="first-name-input"
      />
      <TextInput
        id="last_name"
        label={$_("register_screen.last_name")}
        placeholder={$_("register_screen.last_name_placeholder")}
        bind:value={inputs["lastName"].value}
        info={inputs["lastName"].info}
        error={inputs["lastName"].error}
        testID="last-name-input"
      />
      <TextInput
        id="city"
        label={$_("register_screen.city")}
        placeholder={$_("register_screen.city_placeholder")}
        bind:value={inputs["city"].value}
        info={inputs["city"].info}
        error={inputs["city"].error}
        testID="city-input"
      />
      <DateInput
        id="date_of_birth"
        label={$_("register_screen.date_of_birth")}
        bind:value={inputs["dateOfBirth"].value}
        info={inputs["dateOfBirth"].info}
        error={inputs["dateOfBirth"].error}
        minimumAge={$eventState.data?.data?.ageLimit}
      />
      <RadioInput
        label={$_("register_screen.gender")}
        options={["Male", "Female", "Other"]}
        bind:userSelected={inputs["gender"].value}
        info={inputs["gender"].info}
        error={inputs["gender"].error}
      />

      {#each inputs.businessOrderFields ?? [] as businessOrderField}
        {#if businessOrderField.type === "Checkbox"}
          <CheckboxInput
            id={businessOrderField.businessOrderFieldId.toString()}
            label={extractLabel(businessOrderField.labels)}
            error={businessOrderField.error}
            info={businessOrderField.info}
            bind:checked={businessOrderField.value}
          />
        {:else if businessOrderField.type === "ShortText"}
          <!-- TODO: Implement -->
        {:else if businessOrderField.type === "DatePicker"}
          <!-- TODO: Implement -->
        {/if}
      {/each}

      <div class="celebratix-widget-separator" />
      <button
        class="celebratix-widget-button"
        on:click={handleSubmit}
        data-testid="continue-button"
      >
        <div class="celebratix-widget-text-base-bold">
          {$_("register_screen.button")}
        </div>
      </button>
    </div>
  </div>
</Card>

<style>
  .celebratix-widget-container {
    display: flex;
    flex-direction: column;
    padding: 40px 32px 40px 32px;
    gap: 40px;
  }
  .celebratix-widget-header-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .celebratix-widget-separator {
    width: 100%;
  }
  .celebratix-widget-form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .celebratix-widget-button {
    align-self: flex-end;
    width: 240px;
  }
</style>
