import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { Interceptors } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AccountService } from './services.gen';
import { AffiliateCodesService } from './services.gen';
import { BusinessService } from './services.gen';
import { BusinessesService } from './services.gen';
import { CategoriesService } from './services.gen';
import { ChannelsService } from './services.gen';
import { ConfigService } from './services.gen';
import { CountriesService } from './services.gen';
import { CurrenciesService } from './services.gen';
import { EventsService } from './services.gen';
import { FeaturesService } from './services.gen';
import { MarketplaceService } from './services.gen';
import { MetabaseService } from './services.gen';
import { OrdersService } from './services.gen';
import { PayoutsService } from './services.gen';
import { StripeService } from './services.gen';
import { TicketsService } from './services.gen';
import { UsersService } from './services.gen';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClientV1 {

	public readonly account: AccountService;
	public readonly affiliateCodes: AffiliateCodesService;
	public readonly business: BusinessService;
	public readonly businesses: BusinessesService;
	public readonly categories: CategoriesService;
	public readonly channels: ChannelsService;
	public readonly config: ConfigService;
	public readonly countries: CountriesService;
	public readonly currencies: CurrenciesService;
	public readonly events: EventsService;
	public readonly features: FeaturesService;
	public readonly marketplace: MarketplaceService;
	public readonly metabase: MetabaseService;
	public readonly orders: OrdersService;
	public readonly payouts: PayoutsService;
	public readonly stripe: StripeService;
	public readonly tickets: TicketsService;
	public readonly users: UsersService;

	public readonly request: BaseHttpRequest;

	constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
		this.request = new HttpRequest({
			BASE: config?.BASE ?? '',
			VERSION: config?.VERSION ?? '1.0',
			WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
			CREDENTIALS: config?.CREDENTIALS ?? 'include',
			TOKEN: config?.TOKEN,
			USERNAME: config?.USERNAME,
			PASSWORD: config?.PASSWORD,
			HEADERS: config?.HEADERS,
			ENCODE_PATH: config?.ENCODE_PATH,
			interceptors: {
				request: config?.interceptors?.request ?? new Interceptors(),
				response: config?.interceptors?.response ?? new Interceptors(),
      },
		});

		this.account = new AccountService(this.request);
		this.affiliateCodes = new AffiliateCodesService(this.request);
		this.business = new BusinessService(this.request);
		this.businesses = new BusinessesService(this.request);
		this.categories = new CategoriesService(this.request);
		this.channels = new ChannelsService(this.request);
		this.config = new ConfigService(this.request);
		this.countries = new CountriesService(this.request);
		this.currencies = new CurrenciesService(this.request);
		this.events = new EventsService(this.request);
		this.features = new FeaturesService(this.request);
		this.marketplace = new MarketplaceService(this.request);
		this.metabase = new MetabaseService(this.request);
		this.orders = new OrdersService(this.request);
		this.payouts = new PayoutsService(this.request);
		this.stripe = new StripeService(this.request);
		this.tickets = new TicketsService(this.request);
		this.users = new UsersService(this.request);
	}
}
