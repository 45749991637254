<script lang="ts">
  import {
    Al,
    At,
    Au,
    Ba,
    Be,
    Bg,
    Ch,
    Cz,
    De,
    Dk,
    Ee,
    Es,
    Fi,
    Fo,
    Fr,
    Gb,
    Gr,
    Hr,
    Hu,
    Ie,
    Is,
    It,
    Lt,
    Lu,
    Lv,
    Mc,
    Md,
    Me,
    Mk,
    Mt,
    Nl,
    No,
    Nz,
    Pt,
    Ro,
    Rs,
    Se,
    Si,
    Sk,
    Ua,
    Us,
  } from "svelte-flag-icons";

  type AlCode = "+355";
  type AtCode = "+43";
  type AuCode = "+61";
  type BaCode = "+387";
  type BeCode = "+32";
  type BgCode = "+359";
  type ChCode = "+41";
  type CzCode = "+420";
  type DeCode = "+49";
  type DkCode = "+45";
  type EeCode = "+372";
  type EsCode = "+34";
  type FiCode = "+358";
  type FoCode = "+298";
  type FrCode = "+33";
  type GbCode = "+44" | "en-GB";
  type GrCode = "+30";
  type HrCode = "+385";
  type HuCode = "+36";
  type IeCode = "+353";
  type IsCode = "+354";
  type ItCode = "+39";
  type LtCode = "+370";
  type LuCode = "+352";
  type LvCode = "+371";
  type McCode = "+377";
  type MdCode = "+373";
  type MeCode = "+382";
  type MkCode = "+389";
  type MtCode = "+356";
  type NlCode = "+31" | "nl-NL";
  type NoCode = "+47";
  type NzCode = "+64";
  type PtCode = "+351";
  type RoCode = "+40";
  type RsCode = "+381";
  type SeCode = "+46" | "sv-SE";
  type SiCode = "+386";
  type SkCode = "+421";
  type UaCode = "+380";
  type UsCode = "+1";

  // Params
  export let country:
    | AlCode
    | AtCode
    | AuCode
    | BaCode
    | BeCode
    | BgCode
    | ChCode
    | CzCode
    | DeCode
    | DkCode
    | EeCode
    | EsCode
    | FiCode
    | FoCode
    | FrCode
    | GbCode
    | GrCode
    | HrCode
    | HuCode
    | IeCode
    | IsCode
    | ItCode
    | LtCode
    | LuCode
    | LvCode
    | McCode
    | MdCode
    | MeCode
    | MkCode
    | MtCode
    | NlCode
    | NoCode
    | NzCode
    | PtCode
    | RoCode
    | RsCode
    | SeCode
    | SiCode
    | SkCode
    | UaCode
    | UsCode;

  import { setContext } from "svelte";

  // Define your global icon settings
  const iconCtx = {
    size: "24",
  };
  setContext("iconCtx", iconCtx);
</script>

{#if country === "+46" || country === "sv-SE"}
  <Se />
{:else if country === "+31" || country === "nl-NL"}
  <Nl />
{:else if country === "+44" || country === "en-GB"}
  <Gb />
{:else if country === "+355"}
  <Al />
{:else if country === "+43"}
  <At />
{:else if country === "+61"}
  <Au />
{:else if country === "+387"}
  <Ba />
{:else if country === "+32"}
  <Be />
{:else if country === "+359"}
  <Bg />
{:else if country === "+41"}
  <Ch />
{:else if country === "+420"}
  <Cz />
{:else if country === "+49"}
  <De />
{:else if country === "+45"}
  <Dk />
{:else if country === "+372"}
  <Ee />
{:else if country === "+34"}
  <Es />
{:else if country === "+358"}
  <Fi />
{:else if country === "+298"}
  <Fo />
{:else if country === "+33"}
  <Fr />
{:else if country === "+30"}
  <Gr />
{:else if country === "+385"}
  <Hr />
{:else if country === "+36"}
  <Hu />
{:else if country === "+353"}
  <Ie />
{:else if country === "+354"}
  <Is />
{:else if country === "+39"}
  <It />
{:else if country === "+370"}
  <Lt />
{:else if country === "+352"}
  <Lu />
{:else if country === "+371"}
  <Lv />
{:else if country === "+377"}
  <Mc />
{:else if country === "+373"}
  <Md />
{:else if country === "+382"}
  <Me />
{:else if country === "+389"}
  <Mk />
{:else if country === "+356"}
  <Mt />
{:else if country === "+47"}
  <No />
{:else if country === "+64"}
  <Nz />
{:else if country === "+351"}
  <Pt />
{:else if country === "+40"}
  <Ro />
{:else if country === "+381"}
  <Rs />
{:else if country === "+386"}
  <Si />
{:else if country === "+421"}
  <Sk />
{:else if country === "+380"}
  <Ua />
{:else if country === "+1"}
  <Us />
{/if}
