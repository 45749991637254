<script lang="ts">
  import {
    Icon,
    Clock,
    MapPin,
    GlobeAlt,
    InformationCircle,
  } from "svelte-hero-icons";
  import Modal from "./Modal.svelte";
  import { locale } from "svelte-i18n";
  import { displayDate } from "../utils/utils";
  import type { GetConsumerEventDetailsDto } from "../api/generated/v2";
  import { basePath } from "../api/instances";

  // Params
  export let event: GetConsumerEventDetailsDto | undefined | null;

  // State
  let showModal = false;
</script>

<Modal bind:showModal>
  <div class="celebratix-widget-modal-container">
    <div class="celebratix-widget-text-s">
      {displayDate(event?.startDate, $locale, {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })}
    </div>
    <div class="celebratix-widget-h3">{event?.name}</div>
    <div class="celebratix-widget-text-s">
      {event?.location}{event?.city ? ` - ${event.city}` : ""}
    </div>
    {#if event?.website}
      <a
        class="celebratix-widget-web-link"
        target="_blank"
        href={event?.website}
      >
        <div class="celebratix-widget-info-icon">
          <Icon src={GlobeAlt} mini />
        </div>
        <div class="celebratix-widget-text-l-bold">Website</div>
      </a>
    {/if}
    {#if event?.description}
      <div class="celebratix-widget-text-base">{event?.description}</div>
    {/if}
  </div>
</Modal>

<div class="celebratix-widget-container">
  <div class="celebratix-widget-header">
    <img
      src={`${basePath}/${event?.image?.path}?width=320&format=webp`}
      height="80px"
      width="80px"
      class="celebratix-widget-image"
      alt="event"
    />
    <div class="celebratix-widget-h2">
      {event?.name}
    </div>
  </div>
  <div class="celebratix-widget-row">
    <div class="celebratix-widget-details">
      <div class="celebratix-widget-row-inner">
        <div class="celebratix-widget-icon">
          <Icon src={Clock} />
        </div>
        <div class="celebratix-widget-text-base-bold">
          {displayDate(event?.startDate, $locale, {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
          -
          {displayDate(event?.endDate, $locale, {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>
      </div>
      <div class="celebratix-widget-row-inner">
        <div class="celebratix-widget-icon">
          <Icon src={MapPin} />
        </div>
        <div class="celebratix-widget-text-base">
          {event?.location}{event?.city ? ` - ${event.city}` : ""}
        </div>
      </div>
    </div>
    <button on:click={() => (showModal = true)}>
      <div class="celebratix-widget-info-icon">
        <Icon src={InformationCircle} solid />
      </div>
    </button>
  </div>
</div>

<style>
  button {
    background-color: #d7abfd;
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 50%;
  }
  .celebratix-widget-info-icon {
    color: #1c1917;
    height: 20px;
    width: 20px;
  }
  .celebratix-widget-web-link {
    all: unset;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .celebratix-widget-modal-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 40px 80px;
    background-color: #e7e5e4;
  }
  .celebratix-widget-icon {
    color: #57534e;
    height: 24px;
    width: 24px;
  }
  .celebratix-widget-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
  }
  .celebratix-widget-details {
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: #a8a29e;
  }
  .celebratix-widget-row-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .celebratix-widget-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
  .celebratix-widget-image {
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
    aspect-ratio: 1;
    @media only screen and (max-width: 640px) {
      display: none;
    }
  }
  .celebratix-widget-header {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
  }
</style>
