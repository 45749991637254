import type {
  GetConsumerEventDetailsCommonPaymentFeeDto,
  GetConsumerEventDetailsCurrencyDto,
  GetConsumerEventDetailsPaymentMethodFeeDto,
  TrackEventRequest,
} from "../api/generated/v2";

// Copied from b2c
export const convertDateFromCache = (
  date: Date | undefined | null | string,
) => {
  if (!date) return undefined;
  return date instanceof Date ? date : new Date(date);
};

// Returns true if the date is in the future
export const isFutureDate = (date: Date | undefined | null | string) => {
  const convertedDate = convertDateFromCache(date);

  if (!convertedDate) return false;

  return convertedDate > new Date();
};

// Displays date using the current locale
export const displayDate = (
  date: Date | undefined | null | string,
  locale: string | null | undefined,
  formatOpts?: Intl.DateTimeFormatOptions | undefined,
) => {
  const convertedDate = convertDateFromCache(date);
  if (!convertedDate) return "";

  const localeDate = new Intl.DateTimeFormat(undefined, {
    ...formatOpts,
    timeZone: "Europe/Amsterdam",
  }).format(convertedDate);

  return localeDate;
};

export async function track(trackEventRequest: TrackEventRequest) {
  const {
    trackingEventId,
    trackingEventName,
    facebookPixelId,
    contentCategory,
    contentType,
    contentName,
    contentIds,
    currency,
    value,
  } = trackEventRequest;

  // @ts-expect-error description
  fbq(
    "trackSingle",
    facebookPixelId,
    trackingEventName,
    {
      content_category: contentCategory,
      content_type: contentType,
      content_name: contentName,
      content_ids: contentIds,
      currency: currency,
      value: value,
    },
    {
      eventID: trackingEventId,
    },
  );
}

// Displays price using the current locale
// Adds the `paymentFee` to the base price
export const displayPrice = (
  base: number,
  currency: GetConsumerEventDetailsCurrencyDto | undefined | null,
  paymentFee?:
    | GetConsumerEventDetailsPaymentMethodFeeDto
    | GetConsumerEventDetailsCommonPaymentFeeDto
    | undefined
    | null,
) => {
  let total = base;
  // Add payment fee if it exists
  if (paymentFee) {
    total += base * paymentFee.feeFraction + paymentFee.feeFixed;
  }

  if (!Number.isFinite(total)) return `${currency?.code}?`;

  return new Intl.NumberFormat("default", {
    style: currency ? "currency" : undefined,
    currency: currency?.code,
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: currency?.decimalPlaces,
    maximumFractionDigits: currency?.decimalPlaces,
  }).format(total);
};

export const displayFractionAsPercentage = (
  fraction: number | undefined | null,
  decimals = 2,
) => {
  if (typeof fraction !== "number" || !Number.isFinite(fraction)) return "?%";
  return `${(fraction * 100).toFixed(decimals)}%`;
};

// Displays payment fee price as applied onto the base price
export const displayPaymentFee = (
  base: number,
  currency: GetConsumerEventDetailsCurrencyDto | undefined | null,
  paymentFee:
    | GetConsumerEventDetailsPaymentMethodFeeDto
    | GetConsumerEventDetailsCommonPaymentFeeDto
    | undefined
    | null,
) => {
  let total = 0;
  // Add payment fee if it exists
  if (paymentFee) {
    total += base * paymentFee.feeFraction + paymentFee.feeFixed;
  }

  return displayPrice(total, currency);
};
