<script lang="ts">
  import { Icon, Plus, Minus } from "svelte-hero-icons";
  import {
    decrementTicketQuantity,
    incrementTicketQuantity,
    selectedEventId,
    selectedTickets,
  } from "../store/global";
  import { _ } from "svelte-i18n";
  import type {
    GetConsumerEventDetailsCurrencyDto,
    ConsumersTicketTypeDto,
  } from "../api/generated/v2";
  import { onDestroy } from "svelte";
  import { displayPrice } from "../utils/utils";

  // Params
  export let ticket: ConsumersTicketTypeDto;
  export let currency: GetConsumerEventDetailsCurrencyDto | undefined | null;
  export let tryMarketplace: boolean;

  // State
  let ticketQuantity = 0;

  const numberOfTicketsAvailableForOneOrder = Math.min(
    ticket.maximumAvailableTickets,
    ticket.maximumTicketsPerPurchase,
  );

  const unsubscribe = selectedTickets.subscribe((value) => {
    if (!$selectedEventId) return;
    ticketQuantity =
      value[$selectedEventId]?.id != ticket.id
        ? 0
        : value[$selectedEventId]?.quantity;
  });

  onDestroy(unsubscribe);
</script>

<div class="celebratix-widget-container">
  <div class="celebratix-widget-left-container">
    <div class="celebratix-widget-text-base-bold">
      {ticket.name}
    </div>
    <div class="celebratix-widget-text-l">
      {`${displayPrice(ticket.price, currency)}${
        ticket.serviceFee > 0
          ? ` + ${displayPrice(ticket.serviceFee, currency)} ${$_(
              "tickets_screen.service_fee",
            )}`
          : ""
      }
      `}
    </div>
  </div>
  <div class="celebratix-widget-right-container">
    {#if ticket.availableFrom && new Date(ticket.availableFrom) > new Date()}
      <div class="celebratix-widget-text-base-bold">
        {$_("tickets_screen.coming_soon")}
      </div>
    {:else if numberOfTicketsAvailableForOneOrder < ticket.minimumTicketsPerPurchase}
      <div class="celebratix-widget-text-base-bold">
        {!ticket.soldOutLabel
          ? $_("tickets_screen.sold_out")
          : ticket.soldOutLabel}
      </div>
      <button
        class="celebratix-widget-marketplace-button"
        on:click={() => (tryMarketplace = true)}
      >
        <div class="celebratix-widget-text-base celebratix-widget-link">
          {$_("tickets_screen.try_marketplace")}
        </div>
      </button>
    {:else}
      <div class="celebratix-widget-quantity-container">
        <button
          disabled={ticketQuantity === 0}
          on:click={() => {
            if (!$selectedEventId) return;
            decrementTicketQuantity($selectedEventId, ticket);
          }}
        >
          <Icon src={Minus} size="24" mini />
        </button>
        <div class="celebratix-widget-h5 celebratix-widget-quantity-text">
          {ticketQuantity}
        </div>
        <button
          disabled={ticketQuantity === numberOfTicketsAvailableForOneOrder}
          on:click={() => {
            if (!$selectedEventId) return;
            incrementTicketQuantity($selectedEventId, ticket);
          }}
          data-testid="increment-button"
        >
          <Icon src={Plus} size="24" mini />
        </button>
      </div>
    {/if}
  </div>
</div>

<style>
  .celebratix-widget-marketplace-button {
    all: unset;
    cursor: pointer;
  }
  .celebratix-widget-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    color: #1c1917;
    padding: 20px 32px 20px 32px;
    box-sizing: border-box;
    gap: 4px;
  }
  .celebratix-widget-left-container {
    display: flex;
    flex-direction: column;
  }
  .celebratix-widget-right-container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .celebratix-widget-quantity-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .celebratix-widget-quantity-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: #78716c;
    color: #57534e;
  }
  button {
    width: 48px;
    height: 48px;
    color: #1c1917;
  }
  button:disabled {
    background-color: #d0cfce;
    color: #a8a29e;
    cursor: auto;
  }
</style>
