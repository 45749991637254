<script lang="ts">
  import { Icon, XMark } from "svelte-hero-icons";
  export let showModal = false;

  let dialog: HTMLDialogElement;

  $: if (dialog && showModal) dialog.showModal();
</script>

<!-- svelte-ignore a11y-click-events-have-key-events a11y-no-noninteractive-element-interactions -->
<dialog
  bind:this={dialog}
  on:close={() => (showModal = false)}
  on:click|self={() => dialog.close()}
>
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div on:click|stopPropagation>
    <slot />
  </div>
  <button
    class="celebratix-widget-close-button"
    on:click={() => dialog.close()}
  >
    <Icon src={XMark} />
  </button>
</dialog>

<style>
  .celebratix-widget-close-button {
    all: unset;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    color: #1c1917;
    height: 20px;
    width: 20px;
    padding: 20px;
  }
  dialog {
    width: 800px;
    border-radius: 16px;
    border: none;
    padding: 0;
  }
  dialog::backdrop {
    background: rgba(0, 0, 0, 0.6);
  }
  dialog[open] {
    animation: zoom 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  @keyframes zoom {
    from {
      transform: scale(0.95);
    }
    to {
      transform: scale(1);
    }
  }
  dialog[open]::backdrop {
    animation: fade 0.2s ease-out;
  }
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>
