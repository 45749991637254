<script lang="ts">
  import { Circle } from "svelte-loading-spinners";

  export let loading = false;
</script>

<div class="celebratix-widget-container">
  {#if loading}
    <div class="celebratix-widget-loading-container">
      <Circle size="60" color="#a3f3ef" />
    </div>
  {:else}
    <slot />
  {/if}
</div>

<style>
  .celebratix-widget-container {
    width: 100%;
    border-radius: 24px;
    background: #000000;
  }
  .celebratix-widget-loading-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
