<script lang="ts">
  import Card from "../components/Card.svelte";
  import { _ } from "svelte-i18n";
</script>

<Card>
  <div class="celebratix-widget-container">
    <div class="celebratix-widget-h3">{$_("not_found_screen.title")}</div>
    <div class="celebratix-widget-phone-container">
      <div class="celebratix-widget-text-base">
        {$_("not_found_screen.description")}
      </div>
      <button
        on:click={() => {
          const href = window.location.href;
          const url = new URL(href);
          window.location.replace(url.origin + url.pathname);
        }}
      >
        <div class="celebratix-widget-text-base-bold">
          {$_("not_found_screen.button")}
        </div>
      </button>
    </div>
  </div>
</Card>

<style>
  .celebratix-widget-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px 32px 40px 32px;
  }
  .celebratix-widget-phone-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 20px;
    width: 100%;
  }
</style>
