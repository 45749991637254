<script lang="ts">
  import { _ } from "svelte-i18n";

  export let id: string;
  export let label: string;
  export let value: string;
  export let info = "";
  export let error = "";
  export let minimumAge: number | null | undefined;

  let day = "";
  let month = "";
  let year = "";

  const stripeMinimumAge = 13;
  // Event minimum age -1 year, or stripes minimum age
  const adjustedMinimumAge = Math.max((minimumAge ?? 0) - 1, stripeMinimumAge);
  const startYear = new Date().getFullYear() - adjustedMinimumAge;
  const oldestAge = 120;

  $: {
    if (day && month && year) {
      value = `${year}-${month}-${day}`;
    }
  }
</script>

<div class="celebratix-widget-text-base-bold celebratix-widget-container">
  <label class="celebratix-widget-text-s celebratix-widget-label" for={id}>
    {label}
  </label>
  <div class="celebratix-widget-select-container">
    <select class="celebratix-widget-select-big" bind:value={year}>
      <option value="" disabled selected hidden>{$_("dateInput.year")}</option>
      {#each { length: oldestAge } as _, i}
        <option value={startYear - i}>{startYear - i}</option>
      {/each}
    </select>
    <select class="celebratix-widget-select-big" bind:value={month}>
      <option value="" disabled selected hidden>{$_("dateInput.month")}</option>
      <option value="01">{$_("dateInput.jan")}</option>
      <option value="02">{$_("dateInput.feb")}</option>
      <option value="03">{$_("dateInput.mar")}</option>
      <option value="04">{$_("dateInput.apr")}</option>
      <option value="05">{$_("dateInput.may")}</option>
      <option value="06">{$_("dateInput.jun")}</option>
      <option value="07">{$_("dateInput.jul")}</option>
      <option value="08">{$_("dateInput.aug")}</option>
      <option value="09">{$_("dateInput.sep")}</option>
      <option value="10">{$_("dateInput.oct")}</option>
      <option value="11">{$_("dateInput.nov")}</option>
      <option value="12">{$_("dateInput.dec")}</option>
    </select>
    <select class="celebratix-widget-select-small" bind:value={day}>
      <option value="" disabled selected hidden>{$_("dateInput.day")}</option>
      {#each { length: 31 } as _, i}
        <option value={(i + 1).toString().padStart(2, "0")}>{i + 1}</option>
      {/each}
    </select>
  </div>
  {#if error}
    <div class="celebratix-widget-text-s celebratix-widget-error">{error}</div>
  {:else if info}
    <div class="celebratix-widget-text-s celebratix-widget-info">{info}</div>
  {/if}
</div>

<style>
  .celebratix-widget-select-big {
    flex: 2;
  }
  .celebratix-widget-select-small {
    flex: 1;
  }
  .celebratix-widget-select-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .celebratix-widget-label {
    width: fit-content;
    padding-bottom: 4px;
  }
  .celebratix-widget-container {
    display: flex;
    flex-direction: column;
  }
  .celebratix-widget-info {
    padding-top: 4px;
    color: #a8a29e;
  }
  .celebratix-widget-error {
    padding-top: 4px;
    color: #ff5e7c;
  }
</style>
