import { get, writable } from "svelte/store";
import * as Sentry from "@sentry/svelte";
import type {
  ConsumersTicketTypeDto,
  GetConsumerEventDetailsCurrencyDto,
  CurrencyDto,
  CreatePrimaryOrderDto,
  CreateResaleOrderAsGuestDto,
} from "../api/generated/v2";
import type { MarketplaceListingBasicDto } from "../api/generated/v1";

// TYPES
interface SelectedTicket extends ConsumersTicketTypeDto {
  quantity: number;
  total: number;
}
interface SelectedTickets {
  [eventId: number]: SelectedTicket;
}
interface PaymentDetails {
  ticketQuantity: number;
  ticketName: string;
  ticketPrice: number;
  orderAmount: number;
  orderCurrency:
    | GetConsumerEventDetailsCurrencyDto
    | CurrencyDto
    | undefined
    | null;
  ticketServiceFee?: number;
}

// STATE
export const currentOrder = writable<
  CreatePrimaryOrderDto | CreateResaleOrderAsGuestDto | undefined
>(undefined);
export const isAuth = writable<boolean>(false);
export const userId = writable<string | undefined>(undefined);
export const isRegistered = writable<boolean>(false);
export const userEmail = writable<string>("");
export const userFullName = writable<string>("");
export const selectedTickets = writable<SelectedTickets>({});
export const selectedEventId = writable<number | undefined>(undefined);
export const orderId = writable<string | undefined>(undefined);
export const phone = writable<string>("");
export const selectedListing = writable<MarketplaceListingBasicDto | undefined>(
  undefined,
);
export const pendingPayment = writable<boolean>(false);
export const eventFacebookPixelId = writable<string | null>(null);
export const channelSlug = writable<string | null>(null);
export const trackingSlug = writable<string | null>(null);
export const magic = writable<string | null>(null);
export const couponCode = writable<string | null>(null);
export const paymentDetails = writable<PaymentDetails | undefined>(undefined);
export const selectedPaymentMethod = writable<string | undefined>(undefined);

const setSentryUser = () => {
  Sentry.setUser({
    id: get(userId),
    email: get(userEmail) || undefined,
    phone: get(phone) || undefined,
  });
};
userId.subscribe(() => setSentryUser());
userEmail.subscribe(() => setSentryUser());
phone.subscribe(() => setSentryUser());

// Helper function to calculate the new quantity
// Based on: quantity = delta * n + k
const calculateTicketQuantity = (
  quantity: number,
  min: number,
  max: number,
  delta: number,
  type: "increment" | "decrement",
) => {
  // Default delta to 1
  if (delta <= 0) delta = 1;

  // Use this if you want to start form the delta if delta > min
  // if (type === 'increment' && quantity === 0) {
  //   const initialValue = delta >= min ? delta : min;
  //   return Math.min(initialValue, max);
  // }
  // const k = min <= delta ? 0 : min;

  // Use this if you want to start from min
  if (type === "increment" && quantity === 0) return min;

  const k = min;

  // Special case when decrementing to 0
  if (type === "decrement" && quantity <= k) return 0;

  const n = Math.ceil((quantity - k) / delta);
  const c = type === "increment" ? 1 : -1;

  // Limit the quantity to 0 and the max value
  return Math.min(Math.max(delta * (n + c) + k, 0), max);
};

// ACTIONS
// Increment the quantity of a ticket type for a given event
export const incrementTicketQuantity = (
  eventId: number,
  ticket: ConsumersTicketTypeDto,
) => {
  const {
    price,
    serviceFee,
    minimumTicketsPerPurchase,
    maximumTicketsPerPurchase,
    deltaTicketsPerPurchase,
  } = ticket;

  selectedTickets.update((value) => {
    // Get the current quantity of the selected ticket
    const currentQuantity =
      value[eventId]?.id === ticket.id ? (value[eventId]?.quantity ?? 0) : 0;

    // Calculate the new quantity
    const newQuantity = calculateTicketQuantity(
      currentQuantity,
      minimumTicketsPerPurchase,
      maximumTicketsPerPurchase,
      deltaTicketsPerPurchase,
      "increment",
    );

    const returnValue = {
      ...value,
      [eventId]: {
        ...value[eventId],
        ...ticket,
        quantity: newQuantity,
        total: (price + serviceFee) * newQuantity,
      },
    };

    return returnValue;
  });
};

// Decrement the quantity of a ticket type for a given event
export const decrementTicketQuantity = (
  eventId: number,
  ticket: ConsumersTicketTypeDto,
) => {
  const {
    price,
    serviceFee,
    minimumTicketsPerPurchase,
    maximumTicketsPerPurchase,
    deltaTicketsPerPurchase,
  } = ticket;

  selectedTickets.update((value) => {
    const newQuantity = calculateTicketQuantity(
      value[eventId]?.quantity || 0,
      minimumTicketsPerPurchase,
      maximumTicketsPerPurchase,
      deltaTicketsPerPurchase,
      "decrement",
    );

    return {
      ...value,
      [eventId]: {
        ...value[eventId],
        ...ticket,
        quantity: newQuantity,
        total: (price + serviceFee) * newQuantity,
      },
    };
  });
};
