import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { Interceptors } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AccountsService } from './services.gen';
import { AffiliatesService } from './services.gen';
import { AnalyticsService } from './services.gen';
import { BusinessService } from './services.gen';
import { BusinessesService } from './services.gen';
import { ChannelEventsService } from './services.gen';
import { ChannelsService } from './services.gen';
import { CouponCollectionsService } from './services.gen';
import { EventsService } from './services.gen';
import { ImagesService } from './services.gen';
import { OrdersService } from './services.gen';
import { ResaleService } from './services.gen';
import { TrackingLinksService } from './services.gen';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClientV2 {

	public readonly accounts: AccountsService;
	public readonly affiliates: AffiliatesService;
	public readonly analytics: AnalyticsService;
	public readonly business: BusinessService;
	public readonly businesses: BusinessesService;
	public readonly channelEvents: ChannelEventsService;
	public readonly channels: ChannelsService;
	public readonly couponCollections: CouponCollectionsService;
	public readonly events: EventsService;
	public readonly images: ImagesService;
	public readonly orders: OrdersService;
	public readonly resale: ResaleService;
	public readonly trackingLinks: TrackingLinksService;

	public readonly request: BaseHttpRequest;

	constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
		this.request = new HttpRequest({
			BASE: config?.BASE ?? '',
			VERSION: config?.VERSION ?? '2.0',
			WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
			CREDENTIALS: config?.CREDENTIALS ?? 'include',
			TOKEN: config?.TOKEN,
			USERNAME: config?.USERNAME,
			PASSWORD: config?.PASSWORD,
			HEADERS: config?.HEADERS,
			ENCODE_PATH: config?.ENCODE_PATH,
			interceptors: {
				request: config?.interceptors?.request ?? new Interceptors(),
				response: config?.interceptors?.response ?? new Interceptors(),
      },
		});

		this.accounts = new AccountsService(this.request);
		this.affiliates = new AffiliatesService(this.request);
		this.analytics = new AnalyticsService(this.request);
		this.business = new BusinessService(this.request);
		this.businesses = new BusinessesService(this.request);
		this.channelEvents = new ChannelEventsService(this.request);
		this.channels = new ChannelsService(this.request);
		this.couponCollections = new CouponCollectionsService(this.request);
		this.events = new EventsService(this.request);
		this.images = new ImagesService(this.request);
		this.orders = new OrdersService(this.request);
		this.resale = new ResaleService(this.request);
		this.trackingLinks = new TrackingLinksService(this.request);
	}
}
