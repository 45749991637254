import { writable } from "svelte/store";

interface Params {
  location: string | undefined;
  primary: string | undefined;
  tos: string | undefined;
  business: string | undefined;
  pixel: string | undefined;
  channel: string | undefined;
  tracking: string | undefined;
  noScroll: string | undefined;
}

export const params = writable<Params>({
  location: undefined,
  primary: undefined,
  tos: undefined,
  business: undefined,
  pixel: undefined,
  channel: undefined,
  tracking: undefined,
  noScroll: undefined,
});
